
define(
    'views/table/row_clickable',[
        "backgrid"
    ],
    function (Backgrid) {
        "use strict";

        return Backgrid.Row.extend(
            {
                events: {
                    "click": "_onClick"
                },

                _onClick: function () {
                    console.log("views/table/row_clickable::_onClick");
                }
            }
        );
    }
);
