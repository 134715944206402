
define(
    'main',[
        'backbone',
        'app',
        'bootstrap'
    ],
    function (Backbone, App) {
        console.log("main - creating App instance");
        new App ();

        console.log("main - starting backbone history: " + AppConfig.routesRoot);
        Backbone.history.start(
            {
                root: AppConfig.routesRoot
            }
        );
    }
);
