
define(
    'views/uri/summary',[
        "backbone",
        "templates"
    ],
    function (Backbone, templates) {
        "use strict";

        return Backbone.View.extend(
            {
                template: templates["uri/summary.html"],
                className: "ws-uri-summary",

                initialize: function () {
                    //console.log("views/uri/summary::initialize");
                    this.listenTo(this.model, "change", this.render);
                },

                render: function () {
                    //console.log("views/uri/summary::render: " + this.type);
                    var context = {
                        id: this.model.get("id"),
                        uri: this.model.get("uri"),
                        type: this.model.get("kind"),
                        detailLink: "#uri/" + this.model.get("kind") + "/" + this.model.get("id")
                    };
                    this._metadataToContext(context);

                    this.$el.html(this.template(context));

                    return this;
                },

                _metadataToContext: function (context) {
                    var metadataObj = this.model.get("metadata"),
                        metadata,
                        metaProps = ["name", "description"],
                        i,
                        prop,
                        key;

                    if (metadataObj !== null) {
                        metadata = metadataObj.metadata;
                        for (i = 0; i < metaProps.length; i += 1) {
                            prop = metaProps[i];

                            if (typeof metadata[prop] !== "undefined") {
                                if (typeof metadata[prop]["en-US"] !== "undefined") {
                                    context[prop] = metadata[prop]["en-US"];
                                }
                                else if (typeof metadata[prop].und !== "undefined") {
                                    context[prop] = metadata[prop].und;
                                }
                                else {
                                    for (key in metadata[prop]) {
                                        if (metadata[prop].hasOwnProperty(key)) {
                                            context[prop] = metadata[prop][key];
                                            break;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        );
    }
);
