
define(
    'views/uri/full',[
        "backbone",
        "templates",
        "underscore",
        "moment",
        "model/profileUri"
    ],
    function (Backbone, templates, _, moment, ProfileUri) {
        "use strict";
        var _className = "ws-uri-full";

        return Backbone.View.extend(
            {
                template: templates["uri/full.html"],
                className: _className,

                events: {
                    "click button.ws-uri-full-profileTools-profileAdd": "_profileAdd"
                },

                _nodes: null,

                initialize: function () {
                    console.log("views/uri/full::initialize");
                    this.listenTo(this.model, "change", this.render);
                },

                render: function () {
                    console.log("views/uri/full::render");
                    var context = {
                            id: this.model.get("id"),
                            uri: this.model.get("uri"),
                            _className: _className
                        },
                        metadata,
                        metadataHistory,
                        metaProps = ["name", "description"],
                        i,
                        prop,
                        key,
                        row;

                    if (this.model.get("metadata") !== null) {
                        metadata = this.model.get("metadata").metadata;

                        context.json = JSON.stringify(metadata, null, 4);

                        for (i = 0; i < metaProps.length; i += 1) {
                            prop = metaProps[i];

                            if (typeof metadata[prop]["en-US"] !== "undefined") {
                                context[prop] = metadata[prop]["en-US"];
                            }
                            else if (typeof metadata[prop].und !== "undefined") {
                                context[prop] = metadata[prop].und;
                            }
                            else {
                                for (key in metadata[prop]) {
                                    if (metadata[prop].hasOwnProperty(key)) {
                                        context[prop] = metadata[prop][key];
                                        break;
                                    }
                                }
                            }
                        }
                    }
                    if (this.model.get("metadataHistory")) {
                        metadataHistory = this.model.get("metadataHistory");
                        context.history = [];

                        for (i = 0; i < metadataHistory.length; i += 1) {
                            row = metadataHistory[i];
                            context.history.push(
                                {
                                    id: row.id,
                                    createdAt: moment(row.createdAt).fromNow(),
                                    json: JSON.stringify(row.metadata)
                                }
                            );
                        }
                    }

                    this.detectInProfile();

                    this.$el.html(this.template(context));

                    this._nodes = {
                        errMsg: this.$("." + _className + "-profileTools-errMsg")
                    };

                    return this;
                },

                detectInProfile: function () {
                    var klass = _className + "-inProfile";
                    if (
                        typeof AppConfig.user !== "undefined" &&
                        AppConfig.user !== null &&
                        AppConfig.user.get("profile") !== null &&
                        AppConfig.user.get("profile").get("uris").find(
                            function (needle) {
                                if (this.model.id === needle.get("uriId")) {
                                    return true;
                                }
                            },
                            this
                        )
                    ) {
                        this.$el.addClass(klass);
                    }
                    else {
                        this.$el.removeClass(klass);
                    }
                },

                _profileAdd: function (e) {
                    console.log("views/uri/full::_profileAdd");
                    e.preventDefault();
                    this._nodes.errMsg.html("");

                    var model = new ProfileUri(
                        {
                            profileId: AppConfig.user.get("profile").id,
                            uriId: this.model.id,
                            uri: this.model
                        }
                    );
                    model.save(
                        null,
                        {
                            wait: true,
                            success: _.bind(this._profileAddSuccess, this),
                            error: _.bind(this._profileAddError, this)
                        }
                    );
                },

                _profileAddSuccess: function (model) {
                    console.log("views/uri/full::_profileAddSuccess");
                    AppConfig.user.get("profile").get("uris").add(model);
                    this.detectInProfile();
                },

                _profileAddError: function (model, xhr) {
                    console.log("views/uri/full::_profileAddError");
                    this._nodes.errMsg.html("Failed to add to profile: " + xhr.responseText + " (" + xhr.status + ")");
                }
            }
        );
    }
);
