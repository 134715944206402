
define(
    'views/profileUri/table',[
        "views/table_single_selectable",
        "views/table/cell_uri",
        "constants",
        "backgrid"
    ],
    function (SingleSelectableTable, CellUri, Constants, Backgrid) {
        "use strict";

        var CellKind = Backgrid.Cell.extend(
            {
                className: "cell-kind",

                render: function () {
                    //console.log("views/ownedActivity/table:CellKind::render");

                    this.$el.html(Constants.TYPE_MAP[this.model.get("uri").get("kind")].readable);

                    return this;
                }
            }
        ),
        CellMetadata = Backgrid.Cell.extend(
            {
                className: "cell-metadata",

                render: function () {
                    //console.log("views/profileUri/table:CellMetadata::render");

                    this.$el.html(this.model.get("uri").getReadable());

                    return this;
                }
            }
        );

        return SingleSelectableTable.extend(
            {
                emptyText: "No linked identifiers to display.",
                columns: [
                    {
                        name: "metadata",
                        label: "Name",
                        cell: CellMetadata,
                        editable: false
                    },
                    {
                        name: "kind",
                        label: "Kind",
                        cell: CellKind,
                        editable: false
                    },
                    {
                        name: "uri",
                        label: "Uri",
                        cell: CellUri.extend(
                            {
                                rawValue: function () { return this.model.get("uri").get("uri"); }
                            }
                        ),
                        editable: false
                    }
                ]
            }
        );
    }
);
