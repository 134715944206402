
define(
    'views/table_single_selectable',[
        "views/table",
        "views/table/row_selectable",
        "jquery"
    ],
    function (Table, RowSelectable, $) {
        "use strict";

        return Table.extend(
            {
                row: RowSelectable,
                _currentSelected: null,

                initialize: function () {
                    console.log("views/table_single_selectable::initialize");

                    Table.prototype.initialize.apply(this, arguments);
                    this.listenTo(this.collection, "table:rowSelected", this._rowSelected);
                },

                _rowSelected: function (rowView) {
                    //console.log("views/table_single_selectable::_rowSelected");

                    //
                    // make sure the click eminated from a table that we are controlling
                    // because the event is going through the model first it could be seen
                    // by multiple tables
                    //
                    if (! $.contains(this.el, rowView.el)) {
                        return;
                    }

                    if (rowView === this._currentSelected) {
                        // FEATURE: make this configurable per table
                        this._currentSelected.$el.removeClass("success");
                        return;
                    }

                    if (this._currentSelected !== null) {
                        this._currentSelected.$el.removeClass("success");
                    }
                    this._currentSelected = rowView;
                    this._currentSelected.$el.addClass("success");
                }
            }
        );
    }
);
