
define(
    'views/activityType/summary',[
        "views/uri/summary"
    ],
    function (BaseView) {
        "use strict";

        return BaseView.extend(
            {
                className: "ws-activityType-summary"
            }
        );
    }
);
