
define(
    'views/curatorDashboard/usersPending',[
        "backbone",
        "templates",
        "views/user/listPending"
    ],
    function (Backbone, templates, List) {
        "use strict";

        return List.extend(
            {
                noModels: "No pending users."
            }
        );
    }
);
