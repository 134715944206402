
define(
    'views/faq',[
        "backbone",
        "templates"
    ],
    function (Backbone, templates) {
        "use strict";

        return Backbone.View.extend(
            {
                template: templates["faq.html"],
                className: "ws-faq",

                render: function () {
                    this.$el.html(this.template());

                    return this;
                }
            }
        );
    }
);
