
define(
    'collection/profiles',[
        "backbone",
        "model/profile"
    ],
    function (Backbone, Model) {
        "use strict";

        return Backbone.Collection.extend(
            {
                model: Model,
                url: AppConfig.apiUrl + "/profile"
            }
        );
    }
);
