
define(
    'views/userAcct/contributions',[
        "backbone",
        "templates"
    ],
    function (Backbone, templates) {
        "use strict";

        return Backbone.View.extend(
            {
                template: templates["userAcct/contributions.html"],
                className: "ws-userAcct-contributions",

                render: function () {
                    this.$el.html(this.template());

                    return this;
                }
            }
        );
    }
);
