
define(
    'views/curatorDashboard/profiles',[
        "views/table",
        "backgrid"
    ],
    function (Table, Backgrid) {
        "use strict";

        return Table.extend(
            {
                columns: [
                    {
                        name: "name",
                        label: "Name",
                        cell: "string",
                        editable: false
                    },
                    {
                        name: "label",
                        label: "Label",
                        cell: "string",
                        editable: false
                    },
                    {
                        name: "isPublic",
                        label: "Is Public?",
                        cell: "boolean",
                        editable: false
                    },
                    {
                        name: "createdAt",
                        label: "Date Created",
                        cell: Backgrid.DateCell,
                        editable: false
                    }
                ]
            }
        );
    }
);
