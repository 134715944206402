
define(
    'views/curatorDashboard/profileRequests',[
        "backbone",
        "templates",
        "views/profileRequest/list"
    ],
    function (Backbone, templates, List) {
        "use strict";

        return List.extend(
            {
                noModels: "No requests."
            }
        );
    }
);
