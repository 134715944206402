
define(
    'model/profileRecipe',[
        "backbone",
        "underscore",
        "model/uri",

        // non-positional
        "backbone-relational"
    ],
    function (Backbone, _, Uri) {
        "use strict";

        return Backbone.RelationalModel.extend(
            {
                urlRoot: AppConfig.apiUrl + "/profileRecipe",
                defaults: {
                    profileId: null,
                    uriId: null,
                    isPublic: null,
                    useDescription: null,
                    directions: null,
                    directionsFormat: null
                },
                blacklist: ["createdAt", "updatedAt"],

                toJSON: function () {
                    return _.omit(Backbone.RelationalModel.prototype.toJSON.call(this), this.blacklist);
                },

                relations: [
                    {
                        type: Backbone.HasOne,
                        key: "uri",
                        relatedModel: Uri,
                        includeInJSON: false
                    }
                ]
            }
        );
    }
);
