
define(
    'views/table',[
        "backgrid",
        "underscore"
    ],
    function (Backgrid, _) {
        "use strict";

        //
        // provide an abstracted local class so that if necessary we can
        // easily swap out table implementations, this one also happens
        // to switch to the bootstrap based classes, adds striping, borders,
        // hover styling via bootstrap, as well as empty text implementation
        //
        return Backgrid.Grid.extend(
            {
                emptyText: "No rows to display.",

                constructor: function () {
                    console.log("views/table::constructor");
                    var args = arguments;
                    args[0] = args[0] || {};

                    _.defaults(
                        args[0],
                        {
                            columns: this.columns,
                            row: this.row,
                            className: "table",
                            emptyText: this.emptyText
                        }
                    );

                    Backgrid.Grid.apply(this, args);
                },

                render: function () {
                    console.log("views/table::render");

                    Backgrid.Grid.prototype.render.apply(this, arguments);
                    this.$el.addClass("table-striped");
                    this.$el.addClass("table-bordered");
                    this.$el.addClass("table-hover");

                    return this;
                }
            }
        );
    }
);
