
define(
    'views/userAcct',[
        "backbone",
        "templates",
        "views/userAcct/properties",
        "views/userAcct/contributions",

        // non-positional
        "backbone-subviews"
    ],
    function (Backbone, templates, ViewProperties, ViewContributions) {
        "use strict";

        return Backbone.View.extend(
            {
                template: templates["userAcct.html"],
                className: "ws-userAcct",

                initialize: function () {
                    console.log("views/userAcct::initialize");
                    Backbone.Subviews.add(this);
                },

                render: function () {
                    console.log("views/userAcct::render");
                    this.$el.html(this.template());
                },

                subviewCreators: {
                    properties: function () {
                        console.log("views/userAcct::subviewCreators - properties");
                        return new ViewProperties(
                            {
                                model: this.model
                            }
                        );
                    },

                    contributions: function () {
                        console.log("views/userAcct::subviewCreators - contributions");
                        return new ViewContributions();
                    }
                }
            }
        );
    }
);
