
define(
    'views/profileRequest',[
        "backbone",
        "underscore",
        "templates",
        "model/profileRequest"
    ],
    function (Backbone, _, templates, ProfileRequest) {
        "use strict";

        return Backbone.View.extend(
            {
                template: templates["profileRequest.html"],
                className: "ws-profileRequest",

                events: {
                    "click .ws-profileRequest-send": "doRequest"
                },

                _nodes: null,

                render: function () {
                    console.log("view/profileRequest::render");
                    var context = {
                        prefix: AppConfig.canonicalBaseUri + "/",
                        suffix: "/activity/"
                    };
                    this.$el.html(this.template(context));

                    this._nodes = {
                        label:         this.$(".ws-profileRequest-label"),
                        name:          this.$(".ws-profileRequest-name"),
                        description:   this.$(".ws-profileRequest-description"),
                        indicator:     this.$(".ws-profileRequest-indicator"),
                        failureReason: this.$(".ws-profileRequest-message-failure-reason")
                    };

                    return this;
                },

                doRequest: function (e) {
                    console.log("view/profileRequest::doRequest");
                    e.preventDefault();

                    var label = this._nodes.label.val(),
                        name = this._nodes.name.val(),
                        description = this._nodes.description.val(),
                        errMsg = null,
                        request;

                    if (label === "") {
                        errMsg = "Required field empty: label";
                    }
                    if (name === "") {
                        errMsg = "Required field empty: name";
                    }
                    if (description === "") {
                        errMsg = "Required field empty: description";
                    }

                    if (errMsg !== null) {
                        console.log("view/profileRequest::doRequest - errMsg: " + errMsg);
                        this._requestFailure(errMsg);
                        return;
                    }

                    request = new ProfileRequest(
                        {
                            label: label,
                            name: name,
                            description: description
                        }
                    );
                    request.save(
                        null,
                        {
                            success: _.bind(this._requestSuccess, this),
                            error: _.bind(this._requestError, this)
                        }
                    );
                },

                //
                // successful save of a request returns the request model
                // with the "user" specified which then gets picked up by
                // relational and adds the request to the proper collection
                // which is what triggers the profile request class handling
                // in views/home/user.js
                //
                _requestSuccess: function () {
                    console.log("view/profileRequest::_requestSuccess");

                    this._nodes.label.val("");
                    this._nodes.name.val("");
                    this._nodes.description.val("");

                    this._nodes.indicator.removeClass("error").addClass("success");
                },

                _requestError: function (model, xhr) {
                    console.log("view/profileRequest::_requestError");

                    this._requestFailure(xhr.responseText + " (" +  xhr.status + ")");
                },

                _requestFailure: function (reason) {
                    console.log("view/profileRequest::_requestFailure");

                    this._nodes.indicator.removeClass("success").addClass("error");

                    this._nodes.failureReason.html(reason);
                }
            }
        );
    }
);
