
define(
    'views/recipe/full',[
        "backbone",
        "templates",
        "moment",
        "views/recipe/base"
    ],
    function (Backbone, templates, moment, ViewBase) {
        "use strict";

        return ViewBase.extend(
            {
                template: templates["recipe/full.html"],
                className: "ws-recipe-full",

                render: function () {
                    console.log("views/recipe/full::render");
                    var context = {
                            id: this.model.get("id"),
                            uri: this.model.get("uri")
                        },
                        metadata,
                        metadataHistory,
                        i,
                        row;

                    if (this.model.get("metadata") !== null) {
                        metadata = this.model.get("metadata").metadata;

                        context.json = JSON.stringify(this.model.get("metadata").metadata, null, 4);
                    }
                    if (this.model.get("metadataHistory") !== null) {
                        metadataHistory = this.model.get("metadataHistory");
                        context.history = [];

                        for (i = 0; i < metadataHistory.length; i += 1) {
                            row = metadataHistory[i];
                            context.history.push(
                                {
                                    id: row.id,
                                    createdAt: moment(row.createdAt).fromNow(),
                                    json: JSON.stringify(row.metadata)
                                }
                            );
                        }
                    }

                    if (this.model.get("isPublic") !== null) {
                        this.$el.addClass("ws-recipe-public");
                    }
                    else {
                        this.$el.removeClass("ws-recipe-public");
                    }

                    this.$el.html(this.template(context));

                    return this;
                }
            }
        );
    }
);
