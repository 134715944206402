
define(
    'views/resetPasswordVerify',[
        "backbone",
        "templates",
        "jquery"
    ],
    function (Backbone, templates, $) {
        "use strict";

        return Backbone.View.extend(
            {
                template: templates["resetPasswordVerify.html"],
                className: "ws-resetPasswordVerify",

                events: {
                    "click .ws-resetPasswordVerify-send": "_doRequest"
                },

                _nodes: null,

                render: function (email, token) {
                    this.$el.html(this.template({ email: email, token: token}));

                    this._nodes = {
                        indicator:     this.$(".ws-resetPasswordVerify-indicator"),
                        failureReason: this.$(".ws-resetPasswordVerify-message-failure-reason")
                    };

                    return this;
                },

                _clearError: function () {
                    console.log("views/resetPasswordVerify::_clearError");
                    this._nodes.indicator.removeClass("error");
                },

                _doRequest: function (e) {
                    console.log("view/resetPasswordVerify::_doRequest");
                    e.preventDefault();
                    var reqParams = {
                            newPassword: "New Password",
                            conPassword: "Confirm Password"
                        },
                        prop,
                        requestCfg = {
                            email: this.$(".ws-resetPasswordVerify-email").val(),
                            code: this.$(".ws-resetPasswordVerify-token").val(),
                            newPassword: this.$(".ws-resetPasswordVerify-newPassword").val(),
                            conPassword: this.$(".ws-resetPasswordVerify-conPassword").val()
                        },
                        errMsg = null;

                    for (prop in reqParams) {
                        if (reqParams.hasOwnProperty(prop)) {
                            if (requestCfg[prop] === "") {
                                errMsg = "Required field empty: " + reqParams[prop];
                                break;
                            }
                        }
                    }
                    if (errMsg === null && requestCfg.newPassword.length < 5) {
                        errMsg = "Password must be at least 5 characters";
                    }
                    if (errMsg === null && requestCfg.newPassword !== requestCfg.conPassword) {
                        errMsg = "Passwords do not match";
                    }

                    if (errMsg !== null) {
                        console.log("view/resetPasswordVerify::_doRequest - errMsg: " + errMsg);
                        this._requestFailure(errMsg);
                        return;
                    }

                    delete requestCfg.conPassword;

                    $.ajax(
                        AppConfig.apiUrl + "/password-reset",
                        {
                            type: "PUT",
                            contentType: "application/json",
                            processData: false,
                            data: JSON.stringify(requestCfg),
                            success: this._requestSuccess,
                            error: this._requestError,
                            context: this
                        }
                    );
                },

                _requestSuccess: function () {
                    console.log("view/resetPasswordVerify::_requestSuccess");

                    this._clearError();
                    this._nodes.indicator.addClass("success");
                },

                _requestError: function (xhr, status, error) {
                    console.log("view/resetPasswordVerify::_requestError");
                    var reason = error + " (" + xhr.status + ")";

                    this._requestFailure(reason);
                },

                _requestFailure: function (reason) {
                    console.log("views/resetPasswordVerify::_requestFailure");

                    reason = reason || "Unknown";

                    this._nodes.failureReason.html(reason);
                    this._nodes.indicator.addClass("error");
                }
            }
        );
    }
);
