
define(
    'collection/ownedActivities',[
        "backbone",
        "model/ownedActivity"
    ],
    function (Backbone, Model) {
        "use strict";

        return Backbone.Collection.extend(
            {
                model: Model,
                profleId: null,

                url: function () {
                    console.log("collection/ownedActivities::url");
                    return AppConfig.apiUrl + "/profile/" + this.profileId + "/owned-activities";
                },

                initialize: function (models, cfg) {
                    console.log("collection/ownedActivities::initialize");

                    cfg = cfg || {};

                    if (typeof cfg.profileId !== "undefined") {
                        console.log("collection/ownedActivities::initialize - profileId: " + cfg.profileId);
                        this.profileId = cfg.profileId;
                    }
                }
            }
        );
    }
);
