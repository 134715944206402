
define(
    'views/curatorDashboard',[
        "backbone",
        "templates",
        "collection/profileRequests",
        "views/curatorDashboard/profileRequests",
        "collection/submissions",
        "views/curatorDashboard/submissions",
        "collection/users",
        "views/curatorDashboard/usersPending",
        "views/curatorDashboard/users",
        "collection/profiles",
        "views/curatorDashboard/profiles",

        // non-positional
        "backbone-subviews"
    ],
    function (
        Backbone,
        templates,
        CollectionProfileRequests,
        ViewProfileRequests,
        CollectionSubmissions,
        ViewSubmissions,
        CollectionUsers,
        ViewUsersPending,
        ViewUsers,
        CollectionProfiles,
        ViewProfiles
    ) {
        "use strict";

        return Backbone.View.extend(
            {
                template: templates["curatorDashboard.html"],
                className: "ws-curatorDashboard",
                _currentActive: null,

                subviewCreators: {
                    profileRequests: function () {
                        console.log("views/home::subviewCreators - profileRequests");
                        var view = new ViewProfileRequests(
                            {
                                collection: this._profileRequests
                            }
                        );

                        return view;
                    },
                    profiles: function () {
                        console.log("views/home::subviewCreators - profiles");
                        var view = new ViewProfiles(
                            {
                                collection: this._profiles
                            }
                        );

                        return view;
                    },
                    submissions: function () {
                        console.log("views/home::subviewCreators - submissions");
                        var view = new ViewSubmissions(
                            {
                                collection: this._submissions
                            }
                        );

                        return view;
                    },
                    users: function () {
                        console.log("views/home::subviewCreators - users");
                        var view = new ViewUsers(
                            {
                                collection: this._users
                            }
                        );

                        return view;
                    },
                    usersPending: function () {
                        console.log("views/home::subviewCreators - usersPending");
                        var view = new ViewUsersPending(
                            {
                                collection: this._usersPending
                            }
                        );

                        return view;
                    }
                },

                initialize: function () {
                    Backbone.Subviews.add(this);

                    this._profileRequests = new CollectionProfileRequests();
                    this._submissions = new CollectionSubmissions();
                    this._users = new CollectionUsers();
                    this._usersPending = new CollectionUsers();
                    this._profiles = new CollectionProfiles();

                    this.refresh();
                },

                render: function () {
                    console.log("views/curatorDashboard::render");
                    this.$el.html(this.template());

                    if (this._currentActive === null) {
                        this.setActiveTab("profileRequests");
                    }

                    return this;
                },

                setActiveTab: function (tab) {
                    console.log("views/curatorDashboard::setActiveTab: " + tab);

                    if (this._currentActive !== tab) {
                        // these get both pane and selector
                        this.$(".active").removeClass("active");

                        this.$(".ws-curatorDashboard-tab-" + tab).addClass("active");
                    }
                },

                refresh: function () {
                    console.log("views/curatorDashboard::refresh");
                    this._profileRequests.fetch({reset: true});
                    this._submissions.fetch({reset: true, data: { status: "pending" }});
                    this._usersPending.fetch({data: { isVerified: 0 }});
                    this._profiles.fetch({reset: true});
                    this._users.fetch({});
                }
            }
        );
    }
);
