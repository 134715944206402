
define(
    'views/home/user',[
        "backbone",
        "templates",
        "views/profile/full",
        "views/profileRequest",
        "views/profileRequest/list",

        // non-positional
        "backbone-subviews"
    ],
    function (Backbone, templates, ViewProfile, ViewProfileRequest, ViewProfileRequests) {
        "use strict";

        return Backbone.View.extend(
            {
                template: templates["home/user.html"],
                className: "ws-home-user",

                _nodes: null,
                _subviews: null,
                _currentProfileTab: null,

                initialize: function () {
                    console.log("views/home/user::initialize");
                    this._subviews = {
                        viewProfile: null,
                        viewProfileRequest: null,
                        viewProfileRequests: null
                    };

                    if (this.model) {
                        this._bindModel();
                    }
                },

                _bindModel: function () {
                    console.log("views/home/user::_bindModel");
                    var profileRequests = this.model.get("profileRequests");

                    this.listenTo(this.model, "change:profile", this.render);
                    this.listenTo(profileRequests, "add", this.render);
                    this.listenTo(profileRequests, "reset", this.render);
                    this.listenTo(profileRequests, "remove", this.render);
                    this.listenTo(profileRequests, "change:status", this.render);
                },

                render: function () {
                    console.log("views/home/user::render");
                    var prop;

                    //
                    // detach the subviews so delegated event handlers
                    // aren't lost
                    //
                    for (prop in this._subviews) {
                        if (this._subviews[prop] !== null) {
                            this._subviews[prop].$el.detach();
                        }
                    }

                    this.$el.removeClass("ws-home-user-hasProfileRequests");
                    this.$el.removeClass("ws-home-user-hasOpenProfileRequests");
                    this.$el.html(this.template());

                    this._nodes = {
                        profileRequests: this.$(".ws-home-user-profileRequests-content"),
                        profileRequest: this.$(".ws-home-user-profileRequest-content"),
                        profile: this.$(".ws-home-user-profile")
                    };

                    if (this.model.get("profile") !== null) {
                        if (this._subviews.viewProfile === null) {
                            this._subviews.viewProfile = new ViewProfile(
                                {
                                    model: this.model.get("profile"),
                                    prefix: "home/profile",
                                    startTab: this._currentProfileTab
                                }
                            );
                            this._subviews.viewProfile.render();
                            if (this._currentProfileTab !== null) {
                                this.setProfileTab(this._currentProfileTab);
                            }
                        }
                        this._nodes.profile.html(this._subviews.viewProfile.el);
                    }
                    else {
                        if (this._subviews.viewProfileRequest === null) {
                            this._subviews.viewProfileRequest = new ViewProfileRequest();
                            this._subviews.viewProfileRequest.render();
                        }
                        if (this._subviews.viewProfileRequests === null) {
                            this._subviews.viewProfileRequests = new ViewProfileRequests(
                                {
                                    collection: this.model.get("profileRequests")
                                }
                            );
                            this._subviews.viewProfileRequests.render();
                        }

                        if (this.model.get("profileRequests").length !== 0) {
                            this.$el.addClass("hasProfileRequests");

                            if (this.model.get("profileRequests").hasOpen()) {
                                this.$el.addClass("hasOpenProfileRequests");
                            }
                        }

                        this._nodes.profileRequest.html(this._subviews.viewProfileRequest.el);
                        this._nodes.profileRequests.html(this._subviews.viewProfileRequests.el);
                    }

                    return this;
                },

                setProfileTab: function (tab) {
                    console.log("views/home/user::setProfileTab: " + tab);
                    if (this._subviews.viewProfile !== null) {
                        this._subviews.viewProfile.setActiveTab(tab);
                    }
                    this._currentProfileTab = tab;
                }
            }
        );
    }
);
