
define(
    'views/recipe/table',[
        "views/table_single_selectable",
        "views/table/cell_uri",
        "backgrid"
    ],
    function (SingleSelectableTable, CellUri, Backgrid) {
        "use strict";

        // DRY: ownedActivity/table
        var CellMetadata = Backgrid.Cell.extend(
            {
                className: "cell-metadata",

                render: function () {
                    //console.log("views/recipe/table:CellMetadata::render");

                    this.$el.html(this.model.get("uri").getReadable());

                    return this;
                }
            }
        );

        return SingleSelectableTable.extend(
            {
                emptyText: "No recipes to display.",
                columns: [
                    {
                        name: "metadata",
                        label: "Name",
                        cell: CellMetadata,
                        editable: false
                    },
                    {
                        name: "uri",
                        label: "Uri",
                        cell: CellUri.extend(
                            {
                                rawValue: function () { return this.model.get("uri").get("uri"); }
                            }
                        ),
                        editable: false
                    }
                ]
            }
        );
    }
);
