
define(
    'views/attachmentUsage/list',[
        "backbone",
        "templates",
        "views/list",
        "views/attachmentUsage/summary"
    ],
    function (Backbone, templates, List, SubView) {
        "use strict";

        return List.extend(
            {
                subClassName: "ws-attachmentUsages-list",
                modelViewCtr: SubView,
                noModels: "No attachment usages to display."
            }
        );
    }
);
