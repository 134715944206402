
define(
    'views/submit',[
        "backbone",
        "templates",
        "underscore",
        "model/submission",
        "model/uri",
        "model/ownedActivity",
        "model/profileRecipe"
    ],
    function (Backbone, templates, _, Submission, Uri, OwnedActivity, ProfileRecipe) {
        "use strict";
        var RECIPE_ID = "http://id.tincanapi.com/activitytype/recipe";

        return Backbone.View.extend(
            {
                template: templates["submit.html"],
                className: "ws-submit",

                events: {
                    "click button.ws-submit-haveId-no": "_haveIdNo",
                    "click button.ws-submit-haveId-yes": "_haveIdYes",
                    "change select.ws-submit-selectKind": "_selectKind",
                    "click button.ws-submit-existingUri-search": "_resolveExisting",
                    "click button.ws-submit": "_doRequest",
                    "click button.ws-submitAnother": "_submitAnother"
                },

                _nodes: null,

                _existing: null,
                _existingCache: null,
                _selectedKind: null,

                render: function () {
                    console.log("views/submit::render");
                    var context = {
                        _className: this.className,
                        _recipeType: RECIPE_ID,
                        profilePrefix: "",
                        profileOptions: ""
                    };
                    if (this.options.profile !== null) {
                        context.activityPrefix = this._profilePrefix("activity");
                        context.recipePrefix = this._profilePrefix("recipe");

                        context.profileOptions = [
                            '<option value="recipe">Recipe</option>',
                            '<option value="activity">Activity</option>'
                        ].join("");
                    }

                    this.$el.html(this.template(context));

                    this._nodes = {
                        haveIdNo:           this.$(".ws-submit-haveId-no"),
                        haveIdYes:          this.$(".ws-submit-haveId-yes"),
                        existingUri:        this.$(".ws-submit-existingUri-uri"),
                        foundLink:          this.$(".ws-submit-existingUri-foundLink"),
                        selectKind:         this.$("select.ws-submit-selectKind"),
                        comments:           this.$("textarea.ws-submit-entryData-comments"),
                        metaName:           this.$("input.ws-submit-entryData-name"),
                        metaDesc:           this.$("textarea.ws-submit-entryData-description"),
                        activityUriSegment: this.$("input.ws-submit-entryData-activity-uriSegment"),
                        activityDefinition: this.$("textarea.ws-submit-entryData-activity-definition"),
                        recipeUriSegment:   this.$("input.ws-submit-entryData-recipe-uriSegment"),
                        recipeVersion:      this.$("input.ws-submit-entryData-recipe-version"),
                        recipeDefinition:   this.$("textarea.ws-submit-entryData-recipe-definition"),
                        recipeName:         this.$("input.ws-submit-entryData-recipe-name"),
                        recipeDesc:         this.$("textarea.ws-submit-entryData-recipe-description"),
                        indicator:          this.$(".ws-submit-indicator"),
                        failureReason:      this.$(".ws-submit-message-failure-reason"),
                        nonCuratedUri:      this.$(".ws-submit-nonCuratedSubmission-uri")
                    };

                    if (this._existing !== null) {
                        this._nodes.existingUri.val(this._existingCache);

                        if (this._existing) {
                            this._nodes.haveIdYes.addClass("active");
                        }
                        else {
                            this._nodes.haveIdNo.addClass("active");
                        }
                    }
                    if (this._selectedKind !== null) {
                        this._nodes.selectKind.val(this._selectedKind);
                    }

                    return this;
                },

                _reset: function () {
                    console.log("view/submit::_reset");
                    var removeClasses = [
                        "ws-submit-existingFound",
                        "ws-submit-existingNotFound",
                        "ws-submit-kindSelected",
                        "ws-submit-existingId",
                        "ws-submit-newId"
                    ], i;
                    for (i = 0; i < removeClasses.length; i += 1) {
                        this.$el.removeClass(removeClasses[i]);
                    }

                    this._nodes.haveIdNo.removeClass("active");
                    this._nodes.haveIdYes.removeClass("active");

                    this._nodes.existingUri.val("");
                    this._nodes.selectKind.val("");
                    this._nodes.comments.val("");
                    this._nodes.metaName.val("");
                    this._nodes.metaDesc.val("");
                    this._nodes.activityUriSegment.val("");
                    this._nodes.activityDefinition.val("");
                    this._nodes.recipeUriSegment.val("");
                    this._nodes.recipeVersion.val("");
                    this._nodes.recipeDefinition.val("");
                    this._nodes.recipeName.val("");
                    this._nodes.recipeDesc.val("");

                    this._selectKind();
                    this._existing = null;
                },

                _profilePrefix: function (kind) {
                    return AppConfig.canonicalBaseUri + "/" + kind + "/" + this.options.profile.get("label") + "/";
                },

                _haveIdNo: function (e) {
                    console.log("view/submit::_haveIdNo");
                    e.preventDefault();

                    this._reset();

                    this._nodes.haveIdNo.addClass("active");
                    this.$el.addClass("ws-submit-newId");
                    this._existing = false;
                },

                _haveIdYes: function (e) {
                    console.log("view/submit::_haveIdYes");
                    e.preventDefault();

                    this._reset();

                    this._nodes.haveIdYes.addClass("active");
                    this.$el.addClass("ws-submit-existingId");
                    this._existing = true;
                },

                _selectKind: function () {
                    console.log("view/submit::_selectKind");
                    var value = this.$(this._nodes.selectKind[0].options[this._nodes.selectKind[0].selectedIndex]).val();

                    if (this._selectedKind !== null) {
                        this.$el.removeClass("ws-submit-kindSelected-" + this._selectedKind);
                    }

                    if (value !== "") {
                        this.$el.addClass("ws-submit-kindSelected");

                        this.$el.addClass("ws-submit-kindSelected-" + value);
                        this._selectedKind = value;
                    }
                    else {
                        this.$el.removeClass("ws-submit-kindSelected");
                        this._selectedKind = null;
                    }
                },

                _resolveExisting: function (e) {
                    console.log("view/submit::_resolveExisting");
                    e.preventDefault();

                    this.$el.removeClass("ws-submit-existingFound");
                    this.$el.removeClass("ws-submit-existingNotFound");

                    var value = this.$(".ws-submit-existingUri-uri").val();
                    this._existingCache = value;

                    if (value !== "") {
                        Backbone.$.ajax(
                            AppConfig.apiUrl + "/metadata",
                            {
                                data: {
                                    uri: value
                                },
                                success: this._existingFound,
                                error: this._existingNotFound,
                                context: this
                            }
                        );
                    }
                },

                _existingFound: function (data, textStatus, jqXHR) {
                    console.log("view/submit::_existingFound");
                    var id = jqXHR.getResponseHeader("TinCanRegistryId"),
                        kind = jqXHR.getResponseHeader("TinCanRegistryKind"),
                        foundLink;

                    if (kind === "activity" || kind === "recipe") {
                        foundLink = "#" + kind + "/" + id;
                    }
                    else {
                        foundLink = "#uri/" + kind + "/" + id;
                    }

                    this._nodes.foundLink.attr("href", foundLink);
                    this.$el.addClass("ws-submit-existingFound");
                },

                _existingNotFound: function () {
                    console.log("view/submit::_existingNotFound");

                    this.$el.addClass("ws-submit-existingNotFound");

                    // FEATURE: here we should hit the URI and see if it has metadata
                    // that makes sense to us
                },

                _doRequest: function () {
                    console.log("view/submit::_doRequest");
                    var submission = new Submission (),
                        setProps = {
                            kind: this._selectedKind,
                            comments: this._nodes.comments.val()
                        },
                        recipeDefinition,
                        recipeUriSegment,
                        recipeVersion;

                    if (this._existing) {
                        setProps.uri = this._nodes.existingUri.val();
                    }
                    else {
                        if (this._selectedKind === "activity") {
                            setProps.uriSegment = this._nodes.activityUriSegment.val();
                        }
                        else if (this._selectedKind === "recipe") {
                            recipeUriSegment = this._nodes.recipeUriSegment.val();
                            recipeVersion = this._nodes.recipeVersion.val();

                            if (recipeUriSegment === "") {
                                this._requestError("Recipe requires a URI segment");
                                return;
                            }
                            if (recipeVersion === "") {
                                this._requestError("Recipe requires a Version");
                                return;
                            }

                            setProps.uriSegment = recipeUriSegment + "/" + recipeVersion;
                        }
                    }

                    if (this._selectedKind === "activity") {
                        setProps.metadata = this._nodes.activityDefinition.val();

                        //
                        // for now just make sure that the JSON parses
                        // FEATURE: add definition validation
                        //
                        try {
                            JSON.parse(setProps.metadata);
                        } catch (ex) {
                            this._requestError("Failed to parse activity definition: " + ex);
                            return;
                        }
                    }
                    else if (this._selectedKind === "recipe") {
                        if (this._existing) {
                            //
                            // for now just make sure that the JSON parses
                            // FEATURE: add definition validation
                            //
                            try {
                                recipeDefinition = JSON.parse(this._nodes.recipeDefinition.val());
                            } catch (ex) {
                                this._requestError("Failed to parse recipe definition: " + ex);
                                return;
                            }

                            if (typeof recipeDefinition.type !== "undefined" && recipeDefinition.type !== RECIPE_ID) {
                                this._requestError("Recipe definition has non-matching 'type' value ('" + recipeDefinition.type + "' not " + RECIPE_ID + ")");
                                return;
                            }

                            recipeDefinition.type = RECIPE_ID;
                        }
                        else {
                            recipeDefinition = {
                                type: RECIPE_ID,
                                name: {
                                    "en-US": this._nodes.recipeName.val()
                                },
                                description: {
                                    "en-US": this._nodes.recipeDesc.val()
                                }
                            };
                        }
                        setProps.metadata = JSON.stringify(recipeDefinition);
                    }
                    else {
                        setProps.metadata = JSON.stringify(
                            {
                                name: {
                                    "en-US": this._nodes.metaName.val()
                                },
                                description: {
                                    "en-US": this._nodes.metaDesc.val()
                                }
                            }
                        );
                    }

                    submission.save(
                        setProps,
                        {
                            success: _.bind(this._requestSuccess, this),
                            error: _.bind(
                                function (model, xhr) {
                                    var msg = xhr.statusText;
                                    if (xhr.getResponseHeader("Content-Type") === "text/plain") {
                                        msg += ": " + xhr.responseText;
                                    }

                                    this._requestError(msg);
                                },
                                this
                            )
                        }
                    );
                },

                _requestSuccess: function (model, response, options) {
                    console.log("view/submit::_requestSuccess");
                    var itemId,
                        item,
                        LinkedItemCtr,
                        linkedItemCfg,
                        linkedItemId,
                        linkedItem;

                    this._nodes.nonCuratedUri.html(model.get("uri"));

                    this._nodes.failureReason.html("");
                    this._nodes.indicator.removeClass("error");

                    if (this._selectedKind === "activity" || this._selectedKind === "recipe") {
                        this.$el.addClass("ws-submit-submittedNonCurated");

                        itemId = options.xhr.getResponseHeader("TinCanRegistryId");
                        item = Uri.findOrCreate({ id: itemId, kind: this._selectedKind });

                        LinkedItemCtr = this._selectedKind === "activity" ? OwnedActivity : ProfileRecipe;
                        linkedItemId = options.xhr.getResponseHeader("TinCanRegistryLinkId");
                        linkedItemCfg = {
                            id: linkedItemId,
                            uriId: itemId,
                            uri: item,
                            profileId: AppConfig.user.get("profile").id,
                            isPublic: false,
                            useDescription: ""
                        };
                        if (this._selectedKind === "recipe") {
                            linkedItemCfg.directions = "";
                            linkedItemCfg.directionsFormat = "md";
                        }
                        linkedItem = new LinkedItemCtr (linkedItemCfg);

                        //
                        // made triggering of submitted activity event on .fetch success
                        // because the activity's row wasn't responding to changes in
                        // sub-model events to re-render, so newly submitted activity
                        // showed with a null name and incorrect URI
                        //
                        // FEATURE: make it so rows re-render on sub-model events
                        //
                        item.fetch(
                            {
                                success: _.bind(
                                    function () {
                                        this.trigger("submitted:" + this._selectedKind, linkedItem);
                                    },
                                    this
                                )
                            }
                        );
                    }
                    else {
                        this.$el.addClass("ws-submit-submittedCurated");
                    }
                },

                _requestError: function (reason) {
                    console.log("view/submit::_requestError");

                    this._nodes.indicator.removeClass("success").addClass("error");

                    this._nodes.failureReason.html(reason);
                },

                _submitAnother: function () {
                    console.log("view/submit::_submitAnother");

                    this._reset();

                    this.$el.removeClass("ws-submit-submittedNonCurated");
                    this.$el.removeClass("ws-submit-submittedCurated");
                }
            }
        );
    }
);
