
define(
    'model/submission',[
        "backbone",
        "model/user"
    ],
    function (Backbone, User) {
        "use strict";

        return Backbone.RelationalModel.extend(
            {
                urlRoot: AppConfig.apiUrl + "/submission",
                defaults: {
                    kind: null,
                    comments: null,
                    metadata: null,
                    uri: null,
                    uriSegment: null
                },
                relations: [
                    {
                        type: Backbone.HasOne,
                        key: "user",
                        relatedModel: User,
                        includeInJSON: false
                    }
                ]
            }
        );
    }
);
