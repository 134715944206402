
define(
    'views/profile/full',[
        "backbone",
        "templates",
        "underscore",
        "jquery",
        "showdown",
        "views/profileUri/table",
        "views/ownedActivity/table",
        "views/recipe/table",
        "views/profileUri/full",
        "views/profileRecipe/full"
    ],
    function (Backbone, templates, _, $, Showdown, ViewProfileUris, ViewOwnedActivities, ViewRecipes, ViewProfileUri, ViewProfileRecipe) {
        "use strict";
        var DEFAULT_START_TAB = "recipes";

        return Backbone.View.extend(
            {
                template: templates["profile/full.html"],
                className: "ws-profile-full",
                _nodes: null,
                _currentActiveTab: null,
                _currentActiveItemMap: null,
                _itemViewMap: null,

                events: {
                    "click button.ws-profile-full-makePublic": "_makePublic",
                    "click button.ws-profile-full-makePrivate": "_makePrivate",
                    "click a.ws-profile-full-description-openEditor": "_descriptionEditorOpen",
                    "click button.ws-profile-full-description-editor-save": "_descriptionEditorSave",
                    "click button.ws-profile-full-description-editor-cancel": "_descriptionEditorClose"
                },

                initialize: function (cfg) {
                    console.log("views/profile/full::initialize");
                    Backbone.Subviews.add(this);

                    _.defaults(cfg, { startTab: DEFAULT_START_TAB });
                    if (cfg.startTab === null) {
                        cfg.startTab = DEFAULT_START_TAB;
                    }
                    this._currentActiveTab = cfg.startTab;

                    this._currentActiveItemMap = {
                        activities: null,
                        linkedIds: null,
                        recipes: null
                    };
                    this._itemViewMap = {};
                    this._nodes = {};

                    this.listenTo(this.model, "change", this.render);
                },

                render: function () {
                    console.log("views/profile/full::render");
                    var context = this.model.toJSON(),
                        converter;

                    context._className = this.className;
                    context._linkPrefix = this.options.prefix;

                    if (context.description !== "") {
                        converter = new Showdown.converter();
                        context.descriptionFormatted = converter.makeHtml(context.description);
                    }

                    this.$el.html(this.template(context));

                    if (this.model.get("isPublic")) {
                        this.$el.addClass(this.className + "-public");
                    }
                    else {
                        this.$el.removeClass(this.className + "-public");
                    }
                    if (this.model.get("description") === "") {
                        this.$el.addClass(this.className + "-noDescription");
                    }
                    else {
                        this.$el.removeClass(this.className + "-noDescription");
                    }

                    this._nodes = {
                        item: this.$("." + this.className + "-item"),
                        itemDetail: this.$("." + this.className + "-itemDetail"),
                        error: this.$("." + this.className + "-error")
                    };
                    this._nodes.item.addClass(this.className + "-noSelection");

                    this.setActiveTab(this._currentActiveTab);

                    return this;
                },

                setActiveTab: function (tab) {
                    console.log("views/profile/full::setActiveTab: " + tab);

                    if (tab !== null) {
                        // these get both pane and selector
                        this.$(".active").removeClass("active");
                        this.$("." + this.className + "-tab-" + tab).addClass("active");

                        if (this._currentActiveTab !== null && this._currentActiveItemMap[this._currentActiveTab] !== null) {
                            this._currentActiveItemMap[this._currentActiveTab].$el.detach();
                        }

                        this._currentActiveTab = tab;
                        if (typeof this._nodes.itemDetail !== "undefined") {
                            if (this._currentActiveItemMap[this._currentActiveTab] === null) {
                                this._nodes.item.addClass(this.className + "-noSelection");
                            }
                            else {
                                this._nodes.item.removeClass(this.className + "-noSelection");
                                this._nodes.itemDetail.html(this._currentActiveItemMap[this._currentActiveTab].el);
                            }
                        }
                    }
                },

                subviewCreators: {
                    activities: function () {
                        console.log("views/profile/full::subviewCreators - activities");
                        var collection = this.model.get("activities"),
                            view = new ViewOwnedActivities(
                                {
                                    collection: collection
                                }
                            );
                        this.listenTo(collection, "table:rowSelected", _.partial(this._handleRowSelect, "activities"));
                        this.listenTo(collection, "remove", this._handleRemove);
                        return view;
                    },
                    linkedIds: function () {
                        console.log("views/profile/full::subviewCreators - linkedIds");
                        var collection = this.model.get("uris"),
                            view = new ViewProfileUris(
                                {
                                    collection: collection
                                }
                            );
                        this.listenTo(collection, "table:rowSelected", _.partial(this._handleRowSelect, "linkedIds"));
                        this.listenTo(collection, "remove", this._handleRemove);
                        return view;
                    },
                    recipes: function () {
                        console.log("views/profile/full::subviewCreators - recipes");
                        var collection = this.model.get("recipes"),
                            view = new ViewRecipes(
                                {
                                    collection: collection
                                }
                            );
                        this.listenTo(collection, "table:rowSelected", _.partial(this._handleRowSelect, "recipes"));
                        this.listenTo(collection, "remove", this._handleRemove);
                        return view;
                    }
                },

                _saveError: function (model, xhr) {
                    console.log("views/profile/full::_saveError");
                    this._nodes.error.html("Error: "  + xhr.responseText + " (" + xhr.status + ")");
                },

                _clearError: function () {
                    console.log("views/profile/full::_clearError");
                    this._nodes.error.html("");
                },

                _makePrivate: function () {
                    console.log("views/profile/full::_makePrivate");
                    this.model.save(
                        {
                            isPublic: false
                        },
                        {
                            wait: true,
                            success: _.bind(this._clearError, this),
                            error: _.bind(this._saveError, this)
                        }
                    );
                },

                _makePublic: function () {
                    console.log("views/profile/full::_makePublic");
                    this.model.save(
                        {
                            isPublic: true
                        },
                        {
                            wait: true,
                            success: _.bind(this._clearError, this),
                            error: _.bind(this._saveError, this)
                        }
                    );
                },

                _descriptionEditorOpen: function (e) {
                    console.log("views/profile/full::_descriptionEditorOpen");
                    e.preventDefault();

                    this.$el.addClass(this.className + "-editDescription");
                },

                _descriptionEditorClose: function (e) {
                    console.log("views/profile/full::_descriptionEditorClose");
                    if (e) {
                        e.preventDefault();
                    }

                    this.$el.removeClass(this.className + "-editDescription");
                },

                _descriptionEditorSave: function (e) {
                    console.log("views/profile/full::_descriptionEditorSave");
                    e.preventDefault();

                    this.model.save(
                        {
                            description: this.$("textarea." + this.className + "-description-editor-content").val()
                        },
                        {
                            wait: true,
                            success: _.bind(
                                function () {
                                    console.log("views/profile/full::_descriptionEditorSave - success");
                                    this._clearError();
                                    this._descriptionEditorClose();
                                },
                                this
                            ),
                            error: _.bind(this._saveError, this)
                        }
                    );
                },

                _handleRemove: function (model) {
                    console.log("views/profile/full::_handleRemove");
                    var rowView = this._itemViewMap[model.cid];
                    if (rowView && this._currentActiveItemMap["linkedIds"] === rowView) {
                        this._currentActiveItemMap.linkedIds.remove();
                        this._currentActiveItemMap.linkedIds = null;
                        if (this._currentActiveTab === "linkedIds") {
                            this._nodes.item.addClass(this.className + "-noSelection");
                        }
                    }
                    delete this._itemViewMap[model.cid];
                },

                _handleRowSelect: function (table, rowView) {
                    console.log("views/profile/full::_handleRowSelect - table: " + table);
                    console.log("views/profile/full::_handleRowSelect - rowView: " + rowView.model.cid);
                    var ItemViewCtr,
                        view;

                    //
                    // make sure the click eminated from a table that we are controlling
                    // because the event is going through the model first it could be seen
                    // by multiple tables
                    //
                    if (! $.contains(this.subviews[table].el, rowView.el)) {
                        return;
                    }

                    //
                    // allow for deselection by click on same row
                    //
                    if (this._currentActiveItemMap[table] !== null && rowView.model.cid === this._currentActiveItemMap[table].model.cid) {
                        this._currentActiveItemMap[table].$el.detach();
                        this._currentActiveItemMap[table] = null;

                        this._nodes.item.addClass(this.className + "-noSelection");
                        return;
                    }

                    view = this._itemViewMap[rowView.model.cid];
                    if (! view) {
                        if (table === "recipes") {
                            ItemViewCtr = ViewProfileRecipe;
                        }
                        else {
                            ItemViewCtr = ViewProfileUri;
                        }
                        this._itemViewMap[rowView.model.cid] = view = new ItemViewCtr(
                            {
                                model: rowView.model
                            }
                        );
                        view.render();
                    }
                    if (this._currentActiveItemMap[table] !== null) {
                        this._currentActiveItemMap[table].$el.detach();
                    }
                    this._currentActiveItemMap[table] = view;

                    this._nodes.itemDetail.html(view.el);
                    this._nodes.item.removeClass(this.className + "-noSelection");
                }
            }
        );
    }
);
