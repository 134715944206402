
define(
    'views/table/row_selectable',[
        "views/table/row_clickable"
    ],
    function (RowClickable) {
        "use strict";

        return RowClickable.extend(
            {
                _onClick: function () {
                    console.log("views/profileUri/table/row_selectable::_onClick");
                    RowClickable.prototype._onClick.apply(this, arguments);

                    this.model.trigger("table:rowSelected", this);
                }
            }
        );
    }
);
