
define(
    'views/table/cell_uri',[
        "backgrid",
        "jquery"
    ],
    function (Backgrid, $) {
        "use strict";

        //
        // modified version of Backgrid.UriCell to allow easy
        // override location of data value retrieval, this version
        // matches the original directly, it should be subclassed
        // with an overridden value of rawValue
        //
        return Backgrid.UriCell.extend(
            {
                //
                // the 0.2.6 version of backgrid doesn't have handling
                // for the target/title that we want to leverage so
                // provide that handling here, once the version that was
                // in master at the time of development is tagged for
                // install by bower should be able to remove this property
                //
                target: "_blank",

                rawValue: function () {
                    return this.model.get(this.column.get("name"));
                },

                render: function () {
                    console.log("views/table/cell_uri::render");
                    var rawValue = this.rawValue(),
                        formattedValue = this.formatter.fromRaw(rawValue, this.model);

                    this.$el.empty();
                    this.$el.append(
                        $(
                            "<a>",
                            {
                                tabIndex: -1,
                                href: rawValue,
                                title: this.title || formattedValue,
                                target: this.target
                            }
                        ).text(formattedValue)
                    );
                    this.delegateEvents();
                    return this;
                }
            }
        );
    }
);
