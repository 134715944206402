
define(
    'views/profile/denied',[
        "backbone",
        "templates"
    ],
    function (Backbone, templates) {
        "use strict";

        return Backbone.View.extend(
            {
                template: templates["profile/denied.html"],
                className: "ws-profile-denied",

                render: function () {
                    console.log("views/profile/denied::render");
                    this.$el.html(this.template());

                    return this;
                }
            }
        );
    }
);
