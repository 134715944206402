
define(
    'views/profileRequest/summary',[
        "backbone",
        "templates",
        "underscore"
    ],
    function (Backbone, templates, _) {
        "use strict";

        return Backbone.View.extend(
            {
                template: templates["profileRequest/summary.html"],
                className: "ws-profileRequest-summary",
                events: {
                    "click .ws-profileRequest-summary-approve": "doApprove",
                    "click .ws-profileRequest-summary-deny": "doDeny"
                },

                _nodes: null,

                initialize: function () {
                    console.log("views/profileRequest/summary::initialize");
                    this.listenTo(this.model, "change", this.render);
                },

                render: function () {
                    console.log("views/profileRequest/summary::render");
                    var context = this.model.toJSON();
                    context.user = this.model.get("user").get("name") + " (" + this.model.get("user").get("email") + ")";

                    this.$el.html(this.template(context));

                    this._nodes = {
                        curationResponse: this.$(".ws-profileRequest-summary-curationResponse")
                    };

                    return this;
                },

                doApprove: function (e) {
                    console.log("views/profileRequest/summary::doApprove");
                    e.preventDefault();

                    this.model.save(
                        {
                            status: "approved"
                        },
                        {
                            wait: true,
                            success: _.bind(this._approveSuccess, this),
                            error: _.bind(this._approveError, this)
                        }
                    );
                },

                _approveSuccess: function () {
                    this._nodes.curationResponse.html("Successfully approved");
                },

                _approveError: function (model, xhr) {
                    this._nodes.curationResponse.html("Failed to approve: " + xhr.responseText + " (" + xhr.status + ")");
                },

                doDeny: function (e) {
                    console.log("views/profileRequest/summary::doDeny");
                    e.preventDefault();

                    this.model.save(
                        {
                            status: "denied"
                        },
                        {
                            wait: true,
                            success: _.bind(this._denySuccess, this),
                            error: _.bind(this._denyError, this)
                        }
                    );
                },

                _denySuccess: function () {
                    this._nodes.curationResponse.html("Successfully denied");
                },

                _denyError: function (model, xhr) {
                    this._nodes.curationResponse.html("Failed to deny: " + xhr.responseText + " (" + xhr.status + ")");
                }
            }
        );
    }
);
