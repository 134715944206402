
define(
    'model/uri',[
        "backbone",

        // non-positional
        "backbone-relational"
    ],
    function (Backbone) {
        "use strict";

        return Backbone.RelationalModel.extend(
            {
                defaults: {
                    kind: null,
                    uri: null,
                    metadataHistory: null,
                    metadata: null
                },

                urlRoot: function () {
                    console.log("model/uri::urlRoot");
                    return AppConfig.apiUrl + "/uris/" + this.get("kind");
                },

                getReadable: function () {
                    //console.log("model/uri::getReadable");
                    var metadataObj = this.get("metadata"),
                        metadata,
                        prop = "name",
                        key;

                    if (metadataObj !== null && typeof metadataObj.metadata[prop] !== "undefined") {
                        metadata = metadataObj.metadata;

                        if (typeof metadata[prop]["en-US"] !== "undefined") {
                            return metadata[prop]["en-US"];
                        }
                        if (typeof metadata[prop].und !== "undefined") {
                            return metadata[prop].und;
                        }
                        for (key in metadata[prop]) {
                            if (metadata[prop].hasOwnProperty(key)) {
                                return metadata[prop][key];
                            }
                        }
                    }
                    return this.id;
                }
            }
        );
    }
);
