
define(
    'views/signIn',[
        "backbone",
        "templates",
        "jquery",
        "underscore"
    ],
    function (Backbone, templates, $, _) {
        "use strict";

        return Backbone.View.extend(
            {
                template: templates["signIn.html"],
                className: "ws-signIn",

                events: {
                    "click .ws-signIn-login": "_login",
                    "keypress .ws-signIn-email": "_clearError",
                    "keypress .ws-signIn-password": "_clearError"
                },

                _nodes: null,

                render: function () {
                    this.$el.html(this.template());

                    this._nodes = {
                        email:         this.$(".ws-signIn-email"),
                        password:      this.$(".ws-signIn-password"),
                        indicator:     this.$(".ws-signIn-indicator"),
                        failureReason: this.$(".ws-signIn-message-failure-reason")
                    };

                    this.reset();

                    return this;
                },

                reset: function () {
                    console.log("views/signIn::reset");
                    this._nodes.email.val("");
                    this._nodes.password.val("");

                    this._clearError();
                },

                focusEmail: function () {
                    console.log("views/signIn::focusEmail");
                    this._nodes.email.focus();
                },

                _clearError: function () {
                    console.log("views/signIn::_clearError");
                    this.$el.removeClass("error");
                },

                _login: function (e) {
                    console.log("views/signIn::_login");
                    e.preventDefault();
                    var reqParams = {
                            email: "E-mail Address",
                            password: "Password"
                        },
                        prop,
                        loginCfg = {
                            email: this._nodes.email.val(),
                            password: this._nodes.password.val()
                        },
                        errMsg = null;

                    for (prop in reqParams) {
                        if (reqParams.hasOwnProperty(prop)) {
                            if (loginCfg[prop] === "") {
                                errMsg = "Required field empty: " + reqParams[prop];
                                break;
                            }
                        }
                    }

                    if (errMsg !== null) {
                        console.log("views/signIn::_login - errMsg: " + errMsg);
                        this._loginFailure(errMsg);
                        return;
                    }

                    $.ajax(
                        AppConfig.apiUrl + "/login",
                        {
                            type: "POST",
                            success: _.partial(this._loginSuccess, loginCfg),
                            error: this._loginError,
                            context: this,
                            data: loginCfg
                        }
                    );
                },

                _loginSuccess: function (loginCfg, data) {
                    console.log("views/signIn::_loginSuccess");
                    data.password = loginCfg.password;

                    this.trigger("login", { userData: data });
                    this.reset();
                },

                _loginError: function (xhr, status, error) {
                    console.log("views/signIn::_loginError");
                    var reason = "";
                    if (xhr.status === 401) {
                        reason = xhr.responseText;
                    }
                    else {
                        reason = error + " (" + xhr.status + ")";
                    }

                    this._loginFailure(reason);
                },

                _loginFailure: function (reason) {
                    console.log("views/signIn::_loginFailure");
                    reason = reason || "Unknown";

                    this._nodes.failureReason.html(reason);
                    this.$el.addClass("error");
                }
            }
        );
    }
);
