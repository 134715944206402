
define(
    'views/userAcct/properties',[
        "backbone",
        "templates",
        "underscore"
    ],
    function (Backbone, templates, _) {
        "use strict";

        return Backbone.View.extend(
            {
                template: templates["userAcct/properties.html"],
                className: "ws-userAcct-properties",

                events: {
                    "click button.ws-userAcct-properties-save": "_doSave"
                },

                _nodes: null,

                initialize: function () {
                    console.log("views/userAcct/properties::initialize");
                    this.listenTo(this.model, "change", this.render);
                    this._nodes = {};
                },

                render: function () {
                    console.log("views/userAcct/properties::render");
                    var context = {};

                    if (this.model) {
                        context = {
                            email: this.model.get("email"),
                            name: this.model.get("name"),
                            organization: this.model.get("organization"),
                            phone: this.model.get("phone")
                        };
                    }
                    this.$el.html(this.template(context));

                    this._nodes = {
                        name:          this.$(".ws-userAcct-properties-name"),
                        email:         this.$(".ws-userAcct-properties-email"),
                        phone:         this.$(".ws-userAcct-properties-phone"),
                        organization:  this.$(".ws-userAcct-properties-organization"),
                        newPassword:   this.$(".ws-userAcct-properties-newPassword"),
                        conPassword:   this.$(".ws-userAcct-properties-conPassword"),
                        indicator:     this.$(".ws-userAcct-properties-indicator"),
                        failureReason: this.$(".ws-userAcct-properties-message-failure-reason")
                    };

                    return this;
                },

                _doSave: function (e) {
                    console.log("views/userAcct/properties::_doSave");
                    e.preventDefault();

                    // FEATURE: make it possible on the server to change emails
                    // then re-activate it here
                    var reqParams = {
                            name: "Name"
                            //email: "Email"
                        },
                        prop,
                        userSaveData = {
                            name: this._nodes.name.val(),
                            //email: this._nodes.email.val(),
                            email: this.model.get("email"),
                            organization: this._nodes.organization.val(),
                            phone: this._nodes.phone.val()
                        },
                        newPassword = this._nodes.newPassword.val(),
                        conPassword = this._nodes.conPassword.val(),
                        errMsg = null;

                    for (prop in reqParams) {
                        if (reqParams.hasOwnProperty(prop)) {
                            if (userSaveData[prop] === "") {
                                errMsg = "Required field empty: " + reqParams[prop];
                                break;
                            }
                        }
                    }
                    if (newPassword !== "" || conPassword !== "") {
                        if (errMsg === null && newPassword !== conPassword) {
                            errMsg = "Passwords do not match";
                        }
                        if (errMsg === null && newPassword.length < 5) {
                            errMsg = "Password must be at least 5 characters long";
                        }
                        userSaveData.password = newPassword;
                    }

                    if (errMsg !== null) {
                        console.log("views/userAcct/properties::_doSave - errMsg: " + errMsg);
                        this._saveError(errMsg);
                        return;
                    }

                    //
                    // using .ajax here instead of .save on the model because
                    // we need to be able to pass through the new password before
                    // updating the model instance itself, as soon as we update
                    // the model then the auth header changes and we need to auth
                    // with the original password rather than the new one
                    //
                    Backbone.$.ajax(
                        this.model.url(),
                        {
                            type: "PUT",
                            contentType: "application/json",
                            processData: false,
                            data: JSON.stringify(userSaveData),
                            context: this,
                            success: _.bind(this._saveSuccess, this, newPassword),
                            error: _.bind(
                                function (xhr) {
                                    var msg = xhr.responseText + " (" + xhr.status + ")";

                                    this._saveError(msg);
                                },
                                this
                            )
                        }
                    );
                },

                _saveSuccess: function (newPassword, resp) {
                    console.log("views/userAcct/properties::_registerSuccess");

                    // mimic what happens in Backbone.model.save
                    var serverAttrs = this.model.parse(resp);

                    // the server doesn't give us back password, but we need
                    // it in our model for our auto login cookie
                    if (newPassword !== "") {
                        serverAttrs.password = newPassword;
                    }

                    this.model.set(serverAttrs);

                    this._nodes.indicator.removeClass("error").addClass("success");
                },

                _saveError: function (reason) {
                    console.log("views/userAcct/properties::_saveError");

                    this._nodes.indicator.removeClass("success").addClass("error");
                    this._nodes.failureReason.html(reason);
                }
            }
        );
    }
);
