
define(
    'views/profileRequest/list',[
        "backbone",
        "templates",
        "views/list",
        "views/profileRequest/summary"
    ],
    function (Backbone, templates, List, SubView) {
        "use strict";

        return List.extend(
            {
                subClassName: "ws-profileRequest-list",
                modelViewCtr: SubView,
                noModels: "No profile requests submitted."
            }
        );
    }
);
