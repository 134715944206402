
define(
    'views/curatorDashboard/submissions',[
        "backbone",
        "templates",
        "views/submission/list"
    ],
    function (Backbone, templates, List) {
        "use strict";

        return List.extend(
            {
                noModels: "No submissions."
            }
        );
    }
);
