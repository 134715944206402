
define(
    'model/user',[
        "backbone",
        "jquery",
        "underscore",
        "model/profile",
        "model/profileRequest",
        "collection/profileRequests",

        // non-positional
        "backbone-relational"
    ],
    function (Backbone, $, _, Profile, ProfileRequest, ProfileRequests) {
        "use strict";

        return Backbone.RelationalModel.extend(
            {
                urlRoot: AppConfig.apiUrl + "/user",
                defaults: {
                    email: null,
                    name: null,
                    organization: null,
                    phone: null,
                    password: null,
                    isCurator: false
                },
                relations: [
                    {
                        type: Backbone.HasOne,
                        key: "profile",
                        relatedModel: Profile,
                        autoFetch: true,
                        reverseRelation: {
                            type: Backbone.HasOne,
                            key: "user",
                            includeInJSON: false
                        },
                        includeInJSON: false
                    },
                    {
                        type: Backbone.HasMany,
                        key: "profileRequests",
                        relatedModel: ProfileRequest,
                        autoFetch: true,
                        collectionType: ProfileRequests,
                        collectionOptions: function (model) {
                            console.log("model/user::collectionOptions - profileRequests");
                            return {
                                userId: model.get("id")
                            };
                        },
                        reverseRelation: {
                            key: "user",
                            includeInJSON: false
                        },
                        includeInJSON: false
                    }
                ],

                loadProfile: function (options) {
                    console.log("model/user::loadProfile: " + this.get("id"));
                    var activitiesFetch = { reset: true },
                        urisFetch = { reset: true },
                        recipesFetch = { reset: true },
                        profileRequestsFetch = { reset: true };

                    options = options || {};
                    if (typeof options.activities !== "undefined") {
                        _.extend(activitiesFetch, _.pick(options.activities, "success", "error"));
                    }
                    if (typeof options.uris !== "undefined") {
                        _.extend(urisFetch, _.pick(options.uris, "success", "error"));
                    }
                    if (typeof options.recipes !== "undefined") {
                        _.extend(recipesFetch, _.pick(options.recipes, "success", "error"));
                    }
                    if (typeof options.profiles !== "undefined") {
                        _.extend(profileRequestsFetch, _.pick(options.profileRequests, "success", "error"));
                    }

                    $.ajax(
                        AppConfig.apiUrl + "/user/" + this.get("id") + "/profile",
                        {
                            success: function (data) {
                                console.log("model/user::loadProfile - success");
                                var profile = Profile.findOrCreate(data);
                                this.set("profile", profile);

                                profile.get("activities").fetch(activitiesFetch);
                                profile.get("uris").fetch(urisFetch);
                                profile.get("recipes").fetch(recipesFetch);
                            },
                            error: function () {
                                console.log("model/user::loadProfile - error");
                                this.get("profileRequests").fetch(profileRequestsFetch);
                            },
                            context: this
                        }
                    );
                }
            }
        );
    }
);
