
define(
    'views/user/pending',[
        "backbone",
        "templates",
        "jquery"
    ],
    function (Backbone, templates, $) {
        "use strict";

        return Backbone.View.extend(
            {
                template: templates["user/pending.html"],
                className: "ws-user-pending",
                events: {
                    "click .ws-user-pending-resendVerify": "_doRequest"
                },

                _nodes: null,

                initialize: function () {
                    console.log("views/user/pending::initialize");
                    this.listenTo(this.model, "change", this.render);
                },

                render: function () {
                    console.log("views/user/pending::render");
                    var context = this.model.toJSON();

                    this.$el.html(this.template(context));

                    this._nodes = {
                        curationResponse: this.$(".ws-user-pending-curationResponse")
                    };

                    return this;
                },

                _doRequest: function (e) {
                    console.log("views/user/pending::_doRequest");
                    e.preventDefault();

                    $.ajax(
                        AppConfig.apiUrl + "/user/" + this.model.get("id") + "/resendVerification",
                        {
                            type: "POST",
                            contentType: "application/json",
                            processData: false,
                            data: JSON.stringify(
                                {
                                    verifyUrl: AppConfig.siteUrl + "#userAcctVerify/:id/:token"
                                }
                            ),
                            success: this._requestSuccess,
                            error: this._requestError,
                            context: this
                        }
                    );
                },

                _requestSuccess: function () {
                    this._nodes.curationResponse.html("Successfully sent");
                },

                _requestError: function (xhr) {
                    this._nodes.curationResponse.html("Failed to send: " + xhr.responseText + " (" + xhr.status + ")");
                }
            }
        );
    }
);
