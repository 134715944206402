
define(
    'collection/profileUris',[
        "backbone",
        "model/profileUri"
    ],
    function (Backbone, Model) {
        "use strict";

        return Backbone.Collection.extend(
            {
                model: Model,
                profileId: null,

                url: function () {
                    console.log("collection/profileUris::url");

                    return AppConfig.apiUrl + "/profile/" + this.profileId + "/uris";
                },

                initialize: function (models, cfg) {
                    console.log("collection/profileUris::initialize");

                    cfg = cfg || {};

                    if (typeof cfg.profileId !== "undefined") {
                        console.log("collection/profileUris::initialize - profileId: " + cfg.profileId);
                        this.profileId = cfg.profileId;
                    }
                }
            }
        );
    }
);
