
define(
    'views/activity/base',[
        "backbone",
        "underscore"
    ],
    function (Backbone, _) {
        "use strict";

        return Backbone.View.extend(
            {
                initialize: function () {
                    console.log("views/activity/base::initialize");
                    this.listenTo(this.model, "change", this.render);
                },

                events: {
                    "click button.ws-activity-base-makePublic": "_makePublic",
                    "click button.ws-activity-base-makePrivate": "_makePrivate"
                },

                _makePrivate: function () {
                    console.log("views/activity/base::_makePrivate");
                    this.model.save(
                        {
                            isPublic: false
                        },
                        {
                            wait: true,
                            success: function () {
                                console.log("views/activity/base::_makePrivate - success");
                            },
                            error: _.bind(this._togglePublicError, this)
                        }
                    );
                },

                _makePublic: function () {
                    console.log("views/activity/base::_makePublic");
                    this.model.save(
                        {
                            isPublic: true
                        },
                        {
                            wait: true,
                            success: function () {
                                console.log("views/activity/base::_makePublic - success");
                            },
                            error: _.bind(this._togglePublicError, this)
                        }
                    );
                },

                _togglePublicError: function (model, xhr) {
                    console.log("views/activity/base::_togglePublicError");
                    this.$(".ws-activity-base-toggleMsg").html("Error: "  + xhr.responseText + " (" + xhr.status + ")");
                }
            }
        );
    }
);
