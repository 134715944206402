
define(
    'model/profile',[
        "backbone",
        "model/profileRecipe",
        "collection/profileRecipes",
        "model/ownedActivity",
        "collection/ownedActivities",
        "model/profileUri",
        "collection/profileUris",

        // non-positional
        "backbone-relational"
    ],
    function (Backbone, ProfileRecipe, ProfileRecipes, OwnedActivity, OwnedActivities, ProfileUri, ProfileUris) {
        "use strict";

        return Backbone.RelationalModel.extend(
            {
                urlRoot: AppConfig.apiUrl + "/profile",
                defaults: {
                    name: null,
                    label: null,
                    description: null,
                    isPublic: null
                },
                relations: [
                    {
                        type: Backbone.HasMany,
                        key: "activities",
                        relatedModel: OwnedActivity,
                        collectionType: OwnedActivities,
                        collectionOptions: function (model) {
                            console.log("model/profile::collectionOptions - activities");
                            return { profileId: model.get("id") };
                        },
                        includeInJSON: false
                    },
                    {
                        type: Backbone.HasMany,
                        key: "uris",
                        relatedModel: ProfileUri,
                        collectionType: ProfileUris,
                        collectionOptions: function (model) {
                            console.log("model/profile::collectionOptions - uris");
                            return { profileId: model.get("id") };
                        },
                        includeInJSON: false
                    },
                    {
                        type: Backbone.HasMany,
                        key: "recipes",
                        relatedModel: ProfileRecipe,
                        collectionType: ProfileRecipes,
                        collectionOptions: function (model) {
                            console.log("model/profile::collectionOptions - recipes");
                            return { profileId: model.get("id") };
                        },
                        includeInJSON: false
                    }
                ]
            }
        );
    }
);
