
define(
    'model/profileRequest',[
        "backbone",
        "underscore",

        // non-positional
        "backbone-relational"
    ],
    function (Backbone, _) {
        "use strict";

        return Backbone.RelationalModel.extend(
            {
                urlRoot: AppConfig.apiUrl + "/profile-request",
                defaults: {
                    name: null,
                    label: null,
                    status: null
                },
                blacklist: ["createdAt", "updatedAt"],

                toJSON: function () {
                    return _.omit(Backbone.RelationalModel.prototype.toJSON.call(this), this.blacklist);
                }
            }
        );
    }
);
