
define(
    'views/recipe/denied',[
        "backbone",
        "templates"
    ],
    function (Backbone, templates) {
        "use strict";

        return Backbone.View.extend(
            {
                template: templates["recipe/denied.html"],
                className: "ws-recipe-denied",

                render: function () {
                    console.log("views/recipe/denied::render");
                    this.$el.html(this.template());

                    return this;
                }
            }
        );
    }
);
