
define(
    'app',[
        "backbone",
        "underscore",
        "jquery",
        "constants",
        "model/user",
        "views/home",
        "views/about",
        "views/faq",
        "views/submit",
        "views/authRequired",
        "views/guestPopover",
        "views/signUp",
        "views/userAcct",
        "views/userAcctVerify",
        "views/resetPasswordVerify",
        "views/curatorDashboard",
        "model/profile",
        "model/uri",
        "views/profile/full",
        "views/profile/denied",
        "views/uri/full",
        "views/uri/notFound",
        "views/activity/full",
        "views/activity/denied",
        "views/recipe/full",
        "views/recipe/denied",

        // non-positional
        "jquery.cookie"
    ],
    function (
        Backbone,
        _,
        $,
        constants,
        User,
        ViewHome,
        ViewAbout,
        ViewFaq,
        ViewSubmit,
        ViewAuthRequired,
        ViewGuestPopover,
        ViewSignUp,
        ViewUserAcct,
        ViewUserAcctVerify,
        ViewResetPasswordVerify,
        ViewCuratorDashboard,
        Profile,
        Uri,
        ViewProfile,
        ViewProfileDenied,
        ViewURI,
        ViewURINotFound,
        ViewActivity,
        ViewActivityDenied,
        ViewRecipe,
        ViewRecipeDenied
    ) {
        "use strict";
        var userCookieName = "REG_USER",
            homeTabs = {
                profile: { titlePrefix: "Your Profile" },
                activityTypes: { titlePrefix: "Activity Types" },
                attachmentUsages: { titlePrefix: "Attachment Usages" },
                extensions: { titlePrefix: "Extensions" },
                verbs: { titlePrefix: "Verbs" },
                profiles: { titlePrefix: "Profiles" }
            };

        return Backbone.Router.extend(
            {
                routes: {
                    "content/:page":                     "handleContent",
                    signUp:                              "handleSignUp",
                    userAcct:                            "handleUserAcct",
                    "userAcctVerify/:id/:token":         "handleUserAcctVerify",
                    "resetPasswordVerify/:email/:token": "handleResetPasswordVerify",
                    "profile/:id(/:tab)":                "handleProfile",
                    "activity/:id":                      "handleActivity",
                    "recipe/:id":                        "handleRecipe",
                    "home/:type(/:subtab)":              "handleHomeTab",
                    "uri/:type/:id":                     "handleURI",
                    submit:                              "handleSubmit",
                    "curatorDashboard/:type":            "handleCuratorDashboard",
                    "curatorDashboard":                  "handleCuratorDashboard",
                    "*path":                             "handleDefault"
                },
                contentViews: {
                    about: {
                        ctr: ViewAbout,
                        titleAddn: "About"
                    },
                    faq: {
                        ctr: ViewFaq,
                        titleAddn: "FAQ"
                    }
                },

                _authUser: null,
                _views: {},
                _currentView: null,
                _baseTitle: null,
                _containerNode: null,
                _body: null,

                _guestPopover: null,
                _guestPopoverNode: null,
                _guestPopoverView: null,
                _guestPopoverVisible: false,

                initialize: function () {
                    console.log("app::initialize");

                    this._baseTitle = document.title;
                    this._body = $("body");
                    this._containerNode = $(".main-app");

                    this.on("logout", this._doLogout, this);

                    this._initNavBar();
                    this._initAutoLogin();
                },

                //
                // check to see if there is a user cookie indicating
                // that this user is already logged in, and go ahead
                // and log them back in, note that .navigate calls
                // in _doLogin may not happen because the backbone
                // history hasn't been started yet
                //
                _initAutoLogin: function () {
                    console.log("app::_initAutoLogin");
                    var userCookie = $.cookie(userCookieName);

                    if (userCookie !== null) {
                        this._doLogin(JSON.parse($.cookie(userCookieName)));

                        // refresh from server in case cookie contents outdated
                        this._authUser.fetch();
                    }
                },

                //
                // handle the sign in/out buttons here so that we don't
                // require a route change so they don't have to lose
                // their place to do so
                //
                _initNavBar: function () {
                    console.log("app::_initNavBar");
                    this._body.on(
                        "click",
                        ".ws-signOut",
                        null,
                        _.bind(
                            function (e) {
                                console.log("app::_initNavBar - ws-signOut click handler");
                                e.preventDefault();

                                $.ajax(
                                    AppConfig.apiUrl + "/logout",
                                    {
                                        type: "POST",
                                        success: function () {
                                            this.trigger("logout");
                                        },
                                        error: function (xhr) {
                                            console.log("app::_initNavBar - ws-signOut click handler - logout failed: " + xhr.responseText + " (" + xhr.status + ")");
                                        },
                                        context: this
                                    }
                                );
                            },
                            this
                        )
                    );
                    this._body.on(
                        "click",
                        "a.ws-signIn",
                        null,
                        _.bind(
                            function (e) {
                                console.log("app::_initNavBar - ws-signIn click handler");
                                e.preventDefault();

                                this._toggleGuestPopover();
                            },
                            this
                        )
                    );
                    this._body.on(
                        "click",
                        "a.ws-navRight-signUp",
                        null,
                        _.bind(
                            function () {
                                if (this._guestPopoverVisible) {
                                    this._toggleGuestPopover();
                                }
                            },
                            this
                        )
                    );

                    this._guestPopoverView = new ViewGuestPopover();
                    this.listenTo(
                        this._guestPopoverView,
                        "login",
                        function (e) {
                            console.log("app::_initNavBar - login handler");
                            this._toggleGuestPopover();
                            this._doLogin(e.userData);
                        }
                    );
                    this._guestPopoverView.render();

                    this._guestPopoverNode = $("a.ws-signIn");
                    this._guestPopover = this._guestPopoverNode.popover(
                        {
                            // manually triggering show/hide to allow
                            // for view event reattachment (delegation)
                            trigger: "manual",
                            html: true,
                            placement: "bottom",
                            title: "Sign In",
                            content: this._guestPopoverView.el
                        }
                    );

                    //
                    // this is an ugly hack to handle a problem where the navbar
                    // is not correctly sized after sign in the first time for
                    // a page load
                    //
                    $(".ws-nav-submit").css(
                        {
                            opacity: "",
                            display: ""
                        }
                    );

                    $(".ws-navRight-userAcct").tooltip(
                        {
                            placement: "bottom"
                        }
                    );
                    $(".ws-navRight-signOut").tooltip(
                        {
                            placement: "bottom"
                        }
                    );
                    $(".ws-navRight-curatorDashboard").tooltip(
                        {
                            placement: "bottom"
                        }
                    );
                },

                _toggleGuestPopover: function () {
                    console.log("app::_toggleGuestPopover");
                    if (this._guestPopoverVisible) {
                        //
                        // .detach the view element here so that subviews
                        // event delegations still work, just doing
                        // re-delegation of the outer view's events wasn't
                        // sufficient
                        //
                        this._guestPopoverView.$el.detach();
                        this._guestPopoverNode.popover("hide");
                        this._guestPopoverVisible = false;
                    }
                    else {
                        this._guestPopoverNode.popover("show");
                        this._guestPopover.find(".popover-content").html(this._guestPopoverView.el);
                        this._guestPopoverView.show();
                        this._guestPopoverVisible = true;
                    }
                },

                //
                // some routes need to be retriggered, for instance after login or logout
                // anything that is currently unauthorized/authorized
                // (FEATURE: can we match specific ones?)
                //
                _reDoRoute: function () {
                    console.log("app::_reDoRoute");
                    var fragment = "/" + Backbone.history.fragment;
                    this.navigate("/no-op", { trigger: false, replace: true });
                    this.navigate(fragment, { trigger: true, replace: true });
                },

                _changeView: function (newView, options) {
                    console.log("app::_changeView");
                    options = options || {};
                    options.renderFirst = (typeof options.renderFirst !== "undefined") ? options.renderFirst : true;

                    if (this._currentView !== null && this._currentView === newView) {
                        console.log("app::_changeView - no change needed");
                        return;
                    }

                    if (this._currentView !== null) {
                        this._currentView.$el.detach();
                    }

                    this._currentView = newView;
                    if (options.renderFirst) {
                        this._currentView.render();
                    }

                    this._containerNode.append(this._currentView.$el);
                },

                _changeTitle: function (prefix) {
                    var title = this._baseTitle;
                    if (typeof prefix !== "undefined") {
                        title = prefix + " : " + title;
                    }
                    document.title = title;

                    return this;
                },

                _doLogin: function (userData) {
                    console.log("app::_doLogin");
                    var i;

                    this._authUser = AppConfig.user = User.findOrCreate(userData);

                    this.listenTo(
                        this._authUser,
                        "change",
                        function () {
                            console.log("app::_doLogin - user change");
                            this._updateUser();
                        }
                    );
                    this.listenTo(
                        this._authUser,
                        "change:profile",
                        function (user, profile) {
                            console.log("app::_doLogin - user change:profile");

                            if (profile !== null) {
                                this._body.addClass("hasProfile");
                            }
                            else {
                                this._body.removeClass("hasProfile");
                            }

                            if (this._views.submit) {
                                // FEATURE: make setting profile a method on submit
                                this._views.submit.options.profile = profile;
                                this._views.submit.render();
                            }
                        }
                    );

                    this._loadAuthUserProfile();

                    this._body.addClass("authenticated");
                    this._updateUser();

                    if (this._authUser.get("profile") !== null) {
                        this._body.addClass("hasProfile");
                    }

                    if (this._views._default) {
                        //
                        // logging in means they now get a "Your Profile" tab
                        // that allows them to manage the aspects of requesting
                        // and handling a profile
                        //
                        this._views._default.setUser(this._authUser);

                        //
                        // logging in means they may now be able to see non-public profiles
                        // but really only either their own or for the curator
                        //
                        this._views._default.reloadCollection("profiles");
                    }
                    for (i in this._views) {
                        if (this._views.hasOwnProperty(i)) {
                            if (this._views[i] instanceof ViewProfile) {
                                this._views[i].model.get("activities").fetch();
                                this._views[i].model.get("uris").fetch();
                            }
                        }
                    }

                    if (this._currentView === this._views.signUp || this._currentView === this._views.userAcctVerify || this._currentView === this._views.resetPasswordVerify) {
                        this.navigate("userAcct", { trigger: true });
                    }
                    else {
                        this._reDoRoute();
                    }
                },

                _doLogout: function () {
                    console.log("app::_doLogout");
                    var i,
                        wasCurator = false,
                        hadProfileId = null;

                    $.removeCookie(userCookieName, { secure: AppConfig.secureCookies });

                    this._body.removeClass("authenticated");
                    this._body.removeClass("hasProfile");
                    this._body.removeClass("role-curator");

                    wasCurator = AppConfig.user.get("isCurator");
                    if (AppConfig.user.get("profile") !== null) {
                        hadProfileId = AppConfig.user.get("profile").id;
                    }

                    AppConfig.user = null;

                    if (this._authUser !== null) {
                        this.stopListening(this._authUser);
                        this._authUser = null;

                        for (i in this._views) {
                            if (this._views.hasOwnProperty(i)) {
                                if (this._views[i] instanceof ViewActivity) {
                                    this._views[i].undelegateEvents();
                                    delete this._views[i];
                                }
                                if (this._views[i] instanceof ViewProfile) {
                                    if (wasCurator || this._views[i].model.id === hadProfileId) {
                                        this._views[i].model.get("uris").fetch({reset: true});
                                        this._views[i].model.get("activities").fetch({reset: true});
                                    }
                                    this._views[i].undelegateEvents();
                                    delete this._views[i];
                                }
                            }
                        }
                        if (typeof this._views._default !== "undefined") {
                            this._views._default.reloadCollection("profiles");
                            this._views._default.setUser();
                        }
                        if (typeof this._views.userAcct !== "undefined") {
                            this._views.userAcct.undelegateEvents();
                            delete this._views.userAcct;
                        }
                        if (typeof this._views.submit !== "undefined") {
                            this._views.submit.undelegateEvents();
                            delete this._views.submit;
                        }

                        this._reDoRoute();
                    }
                },

                _updateUser: function () {
                    console.log("app::_updateUser");

                    //
                    // store the user information in a cookie so that we can re-login
                    // automatically this includes the password for now so that we don't
                    // have to re-request password for each session and while we don't
                    // have a session token on the server
                    //
                    var cookieValue = this._authUser.toJSON();
                    delete cookieValue.password;

                    $.cookie(userCookieName, JSON.stringify(cookieValue), { secure: AppConfig.secureCookies });

                    $(".ws-navRight-userInfo-name").html(this._authUser.get("name"));

                    if (this._authUser.get("isCurator")) {
                        this._body.addClass("role-curator");
                    }
                },

                _loadAuthUserProfile: function () {
                    console.log("app::_loadAuthUserProfile");
                    this._authUser.loadProfile(
                        {
                            uris: {
                                success: _.bind(
                                    function () {
                                        console.log("app::_loadAuthUserProfile - uris success callback");
                                        var i;

                                        for (i in this._views) {
                                            if (this._views.hasOwnProperty(i)) {
                                                if (this._views[i] instanceof ViewURI) {
                                                    this._views[i].detectInProfile();
                                                }
                                            }
                                        }
                                    },
                                    this
                                )
                            }
                        }
                    );
                },

                handleDefault: function () {
                    console.log("app::handleDefault");
                    var view = this._views._default,
                        viewCfg;

                    if (typeof view === "undefined") {
                        viewCfg = {};
                        if (this._authUser !== null) {
                            viewCfg.user = this._authUser;
                        }
                        view = this._views._default = new ViewHome(viewCfg);
                        view.render();
                    }

                    this._changeTitle();
                    this._changeView(view, { renderFirst: false });
                },

                handleContent: function (page) {
                    console.log("app::handleContent - page: " + page);
                    var view = this._views[page],
                        ViewCtr;

                    if (typeof view === "undefined") {
                        ViewCtr = this.contentViews[page].ctr;
                        view = this._views[page] = new ViewCtr ();
                    }
                    this._changeTitle(this.contentViews[page].titleAddn);
                    this._changeView(view);
                },

                handleHomeTab: function (type, subtab) {
                    console.log("app::handleHomeTab: " + type);
                    if (type === "profile" && this._authUser === null) {
                        this.navigate("home/activityTypes", { trigger: true });
                        return;
                    }
                    this.handleDefault();
                    this._changeTitle(homeTabs[type].titlePrefix);

                    this._currentView.setActiveTab(type, subtab);
                },

                handleURI: function (kind, id) {
                    console.log("app::handleURI - kind: " + kind + ", id: " + id);
                    var cacheKey = kind + id;

                    if (typeof this._views[cacheKey] !== "undefined") {
                        this._changeView(this._views[cacheKey]);
                        return;
                    }

                    Uri.findOrCreate({ id: id, kind: kind }).fetch(
                        {
                            success: _.bind(
                                function (model) {
                                    var view;
                                    this._views[cacheKey] = view = new ViewURI(
                                        {
                                            model: model
                                        }
                                    );
                                    this._changeTitle(model.getReadable() + " (" + constants.TYPE_MAP[kind].readable + ")");
                                    this._changeView(view);
                                },
                                this
                            ),
                            error: _.bind(
                                function () {
                                    this._changeTitle(constants.TYPE_MAP[kind].readable + " Not Found");
                                    this._changeView(this._uriNotFound());
                                },
                                this
                            )
                        }
                    );
                },

                handleActivity: function (id) {
                    console.log("app::handleActivity id: " + id);
                    var cacheKey = "activity-" + id;

                    if (typeof this._views[cacheKey] !== "undefined") {
                        this._changeView(this._views[cacheKey]);
                        return;
                    }

                    //
                    // need to check to see if they have access
                    // to see the activity detail, have to be owner of
                    // the profile or the activity must be public
                    //

                    // FEATURE: recognize previously fetched
                    Uri.findOrCreate({ id: id, kind: "activity" }).fetch(
                        {
                            success: _.bind(
                                function (model) {
                                    var view;

                                    this._views[cacheKey] = view = new ViewActivity(
                                        {
                                            model: model
                                        }
                                    );
                                    this._changeTitle(model.getReadable() + " (Activity)");
                                    this._changeView(view);
                                },
                                this
                            ),
                            error: _.bind(
                                function (model, response) {
                                    if (response.status === 403) {
                                        this._changeTitle("Activity Denied");
                                        this._changeView(this._activityDenied());
                                    }
                                    else {
                                        this._changeTitle("Activity Not Found");
                                        this._changeView(this._uriNotFound());
                                    }
                                },
                                this
                            )
                        }
                    );
                },

                handleRecipe: function (id) {
                    console.log("app::handleRecipe id: " + id);
                    var cacheKey = "recipe-" + id;

                    if (typeof this._views[cacheKey] !== "undefined") {
                        this._changeView(this._views[cacheKey]);
                        return;
                    }

                    //
                    // need to check to see if they have access
                    // to see the recipe detail, have to be owner of
                    // the profile or the recipe must be public
                    //

                    // FEATURE: recognize previously fetched
                    Uri.findOrCreate({ id: id, kind: "recipe" }).fetch(
                        {
                            success: _.bind(
                                function (model) {
                                    var view;

                                    this._views[cacheKey] = view = new ViewRecipe(
                                        {
                                            model: model
                                        }
                                    );
                                    this._changeTitle(model.getReadable() + " (Recipe)");
                                    this._changeView(view);
                                },
                                this
                            ),
                            error: _.bind(
                                function (model, response) {
                                    if (response.status === 403) {
                                        this._changeTitle("Recipe Denied");
                                        this._changeView(this._recipeDenied());
                                    }
                                    else {
                                        this._changeTitle("Recipe Not Found");
                                        this._changeView(this._uriNotFound());
                                    }
                                },
                                this
                            )
                        }
                    );
                },

                _authRequired: function () {
                    var prop = "authRequired";
                    if (typeof this._views[prop] === "undefined") {
                        this._views[prop] = new ViewAuthRequired();
                    }
                    return this._views[prop];
                },

                _uriNotFound: function () {
                    var prop = "uriNotFound";
                    if (typeof this._views[prop] === "undefined") {
                        this._views[prop] = new ViewURINotFound();
                    }
                    return this._views[prop];
                },

                _activityDenied: function () {
                    var prop = "activityDenied";
                    if (typeof this._views[prop] === "undefined") {
                        this._views[prop] = new ViewActivityDenied();
                    }
                    return this._views[prop];
                },

                _recipeDenied: function () {
                    var prop = "recipeDenied";
                    if (typeof this._views[prop] === "undefined") {
                        this._views[prop] = new ViewRecipeDenied();
                    }
                    return this._views[prop];
                },

                _profileDenied: function () {
                    var prop = "profileDenied";
                    if (typeof this._views[prop] === "undefined") {
                        this._views[prop] = new ViewProfileDenied();
                    }
                    return this._views[prop];
                },

                handleSignUp: function () {
                    console.log("app::handleSignUp");
                    var view = this._views.signUp;

                    if (this._authUser !== null) {
                        this.trigger("logout");
                    }

                    if (typeof view === "undefined") {
                        view = this._views.signUp = new ViewSignUp();
                    }
                    this._changeTitle("Sign Up");
                    this._changeView(view);
                },

                handleUserAcct: function () {
                    console.log("app::handleUserAcct");
                    var view = this._views.userAcct;

                    if (this._authUser === null) {
                        this._changeView(this._authRequired());
                        return;
                    }

                    if (typeof view === "undefined") {
                        view = this._views.userAcct = new ViewUserAcct(
                            {
                                model: this._authUser
                            }
                        );
                    }
                    if (this._authUser.get("profile") === null) {
                        this._loadAuthUserProfile();
                    }

                    this._changeTitle("User Account");
                    this._changeView(view);
                },

                handleUserAcctVerify: function (id, token) {
                    console.log("app::handleUserAcctVerify: " + id + " - " + token);
                    var view = this._views.userAcctVerify;

                    if (typeof view === "undefined") {
                        view = this._views.userAcctVerify = new ViewUserAcctVerify();
                    }
                    view.doVerify(id, token);

                    this._changeView(view);
                },

                handleResetPasswordVerify: function (email, token) {
                    console.log("app::handleResetPasswordVerify: " + email + " - " + token);
                    var view = this._views.resetPasswordVerify;

                    if (typeof view === "undefined") {
                        view = this._views.resetPasswordVerify = new ViewResetPasswordVerify().render(email, token);
                    }
                    else {
                        view.render(email, token);
                    }

                    this._changeView(view, { renderFirst: false });
                },

                handleProfile: function (id, tab) {
                    console.log("app::handleProfile id: " + id);
                    console.log("app::handleProfile tab: " + tab);

                    var cacheKey = "profile-" + id,
                        view = this._views[cacheKey],
                        model;

                    if (typeof view === "undefined") {
                        model = Profile.findOrCreate(
                            {
                                id: id
                            }
                        );
                        model.fetch(
                            {
                                success: _.bind(
                                    function (model) {
                                        var view;
                                        model.get("recipes").fetch();
                                        model.get("activities").fetch();
                                        model.get("uris").fetch();

                                        this._views[cacheKey] = view = new ViewProfile(
                                            {
                                                model: model,
                                                prefix: "profile/" + model.id,
                                                startTab: tab
                                            }
                                        );
                                        this._changeTitle(model.get("name") + " (Profile)");
                                        this._changeView(view);
                                    },
                                    this
                                ),
                                error: _.bind(
                                    function (model, response) {
                                        if (response.status === 403) {
                                            this._changeTitle("Profile Denied");
                                            this._changeView(this._profileDenied());
                                        }
                                        else {
                                            this._changeTitle("Profile Not Found");
                                            this._changeView(this._uriNotFound());
                                        }
                                    },
                                    this
                                )
                            }
                        );
                        return;
                    }
                    this._changeView(view);
                    if (typeof tab !== "undefined") {
                        view.setActiveTab(tab);
                    }
                },

                handleSubmit: function () {
                    console.log("app::handleSubmit");
                    var view = this._views.submit;

                    if (this._authUser === null) {
                        this._changeView(this._authRequired());
                        return;
                    }

                    if (typeof view === "undefined") {
                        view = this._views.submit = new ViewSubmit(
                            {
                                profile: this._authUser.get("profile")
                            }
                        );
                        view.render();

                        this.listenTo(
                            view,
                            "submitted:activity",
                            function (ownedActivity) {
                                console.log("app::handleSubmit - submitted:activity handler");
                                this._authUser.get("profile").get("activities").add(ownedActivity);
                            }
                        );
                        this.listenTo(
                            view,
                            "submitted:recipe",
                            function (profileRecipe) {
                                console.log("app::handleSubmit - submitted:recipe handler");
                                this._authUser.get("profile").get("recipes").add(profileRecipe);
                            }
                        );
                    }

                    this._changeTitle("Submission");
                    this._changeView(view, { renderFirst: false });
                },

                handleCuratorDashboard: function (type) {
                    console.log("app::handleCuratorDashboard");
                    var view = this._views.curatorDashboard;

                    if (this._authUser === null || !this._authUser.get("isCurator")) {
                        this._changeView(this._authRequired());
                        return;
                    }

                    if (typeof view === "undefined") {
                        view = this._views.curatorDashboard = new ViewCuratorDashboard();
                        view.render();
                    }
                    else {
                        view.refresh();
                    }

                    this._changeTitle("Curator Dashboard");
                    this._changeView(view, { renderFirst: false });

                    if (typeof type !== "undefined") {
                        view.setActiveTab(type);
                    }
                }
            }
        );
    }
);
