
define(
    'views/user/listPending',[
        "backbone",
        "templates",
        "views/list",
        "views/user/pending"
    ],
    function (Backbone, templates, List, SubView) {
        "use strict";

        return List.extend(
            {
                subClassName: "ws-user-listPending",
                modelViewCtr: SubView,
                noModels: "No users pending."
            }
        );
    }
);
