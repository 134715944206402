
define(
    'constants',[],
    function () {
        var constants = {
            TYPE_MAP: {
                extension: {
                    readable: "Extension",
                    uri: "extension"
                },
                activityType: {
                    readable: "Activity Type",
                    uri: "activitytype"
                },
                activity: {
                    readable: "Activity",
                    uri: "activity"
                },
                verb: {
                    readable: "Verb",
                    uri: "verb"
                },
                attachmentUsage: {
                    readable: "Attachment Usage",
                    uri: "attachment"
                }
            }
        };

        return constants;
    }
);
