
define(
    'views/signUp',[
        "backbone",
        "templates",
        "underscore",
        "model/user"
    ],
    function (Backbone, templates, _, User) {
        "use strict";

        return Backbone.View.extend(
            {
                template: templates["signUp.html"],
                className: "ws-signUp",

                events: {
                    "click button.ws-signUp": "registerUser"
                },

                _nodes: null,

                render: function () {
                    this.$el.html(this.template());

                    this._nodes = {
                        name:          this.$(".ws-signUp-name"),
                        email:         this.$(".ws-signUp-email"),
                        phone:         this.$(".ws-signUp-phone"),
                        organization:  this.$(".ws-signUp-organization"),
                        newPassword:   this.$(".ws-signUp-newPassword"),
                        conPassword:   this.$(".ws-signUp-conPassword"),
                        indicator:     this.$(".ws-signUp-indicator"),
                        failureReason: this.$(".ws-signUp-message-failure-reason")
                    };

                    return this;
                },

                registerUser: function (e) {
                    console.log("views/signUp::registerUser");
                    e.preventDefault();
                    var reqParams = {
                            name: "Name",
                            email: "Email",
                            newPassword: "New Password",
                            conPassword: "Confirm Password"
                        },
                        prop,
                        userCfg = {
                            name: this._nodes.name.val(),
                            email: this._nodes.email.val(),
                            organization: this._nodes.organization.val(),
                            phone: this._nodes.phone.val(),
                            newPassword: this._nodes.newPassword.val(),
                            conPassword: this._nodes.conPassword.val()
                        },
                        user,
                        userSaveData,
                        errMsg = null;

                    for (prop in reqParams) {
                        if (reqParams.hasOwnProperty(prop)) {
                            if (userCfg[prop] === "") {
                                errMsg = "Required field empty: " + reqParams[prop];
                                break;
                            }
                        }
                    }
                    if (errMsg === null && userCfg.newPassword.length < 5) {
                        errMsg = "Password must be at least 5 characters long";
                    }
                    if (errMsg === null && userCfg.newPassword !== userCfg.conPassword) {
                        errMsg = "Passwords do not match";
                    }

                    if (errMsg !== null) {
                        console.log("views/signUp::registerUser - errMsg: " + errMsg);
                        this._registerFailure(errMsg);
                        return;
                    }

                    userCfg.password = userCfg.newPassword;
                    delete userCfg.newPassword;
                    delete userCfg.conPassword;

                    user = new User (userCfg);

                    //
                    // need to be able to add the "verifyUrl" so need to create
                    // our own structure to save
                    //
                    userSaveData = user.toJSON();
                    userSaveData.verifyUrl = AppConfig.siteUrl + "#userAcctVerify/:id/:token";

                    user.save(
                        userSaveData,
                        {
                            success: _.bind(this._registerSuccess, this),
                            error: _.bind(this._registerError, this)
                        }
                    );
                },

                _registerSuccess: function () {
                    console.log("views/signUp::_registerSuccess");
                    var prop;

                    for (prop in this._nodes) {
                        if (prop !== "indicator" && prop !== "failureReason") {
                            this._nodes[prop].val("");
                        }
                    }

                    this._nodes.indicator.removeClass("error").addClass("success");
                },

                _registerError: function (model, xhr) {
                    console.log("views/signUp::_registerError");
                    var msg = "";
                    if (xhr.status === 409) {
                        msg = "account already exists";
                    }
                    else {
                        msg = xhr.responseText + " (" + xhr.status + ")";
                    }

                    this._registerFailure(msg);
                },

                _registerFailure: function (reason) {
                    console.log("views/signUp::_registerFailure");

                    this._nodes.indicator.removeClass("success").addClass("error");

                    this._nodes.failureReason.html(reason);
                }
            }
        );
    }
);
