
define(
    'views/guestPopover',[
        "backbone",
        "templates",
        "views/signIn",
        "views/resetPassword",

        // non-positional
        "backbone-subviews"
    ],
    function (Backbone, templates, ViewSignIn, ViewResetPassword) {
        "use strict";

        return Backbone.View.extend(
            {
                template: templates["guestPopover.html"],
                className: "ws-guestPopover",
                events: {
                    "click .ws-guestPopover-stateToggle": "_toggleState"
                },

                _currentView: null,

                initialize: function () {
                    console.log("views/guestPopover::initialize");
                    Backbone.Subviews.add(this);
                },

                render: function () {
                    console.log("views/guestPopover::render");
                    this.$el.html(this.template());

                    return this;
                },

                subviewCreators: {
                    signIn: function () {
                        console.log("views/guestPopover::subviewCreators - signIn");
                        var view = new ViewSignIn();

                        this.listenTo(
                            view,
                            "login",
                            function (e) {
                                console.log("views/guestPopover::subviewCreators - signIn - login handler");
                                this.trigger("login", e);
                            }
                        );
                        return view;
                    },

                    resetPassword: function () {
                        console.log("views/guestPopover::subviewCreators - resetPassword");
                        return new ViewResetPassword();
                    }
                },

                show: function () {
                    console.log("views/guestPopover::show");
                    this.$el.removeClass("ws-guestPopover-state-resetPassword");
                    this.subviews.signIn.focusEmail();
                    this._currentView = this.subviews.signIn;
                },

                _toggleState: function (e) {
                    console.log("views/guestPopover::_toggleState");
                    e.preventDefault();

                    if (this._currentView === null || this._currentView === this.subviews.resetPassword) {
                        this.$el.removeClass("ws-guestPopover-state-resetPassword");
                        this.subviews.signIn.focusEmail();
                        this._currentView = this.subviews.signIn;
                    }
                    else {
                        this.$el.addClass("ws-guestPopover-state-resetPassword");
                        this.subviews.resetPassword.focusEmail();
                        this._currentView = this.subviews.resetPassword;
                    }
                }
            }
        );
    }
);
