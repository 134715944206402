
define(
    'views/profileRecipe/full',[
        "backbone",
        "templates",
        "underscore",
        "showdown",
        "views/profileUri/full"
    ],
    function (Backbone, templates, _, Showdown, ProfileUri) {
        "use strict";

        return ProfileUri.extend(
            {
                template: templates["profileRecipe/full.html"],
                className: "ws-profileRecipe-full",
                _nodes: null,

                events: {
                    "click button.ws-profileRecipe-full-makePublic": "_makePublic",
                    "click button.ws-profileRecipe-full-makePrivate": "_makePrivate",
                    "click a.ws-profileRecipe-full-description-openEditor": "_descriptionEditorOpen",
                    "click button.ws-profileRecipe-full-description-editor-save": "_descriptionEditorSave",
                    "click button.ws-profileRecipe-full-description-editor-cancel": "_descriptionEditorClose",
                    "click a.ws-profileRecipe-full-directions-openEditor": "_directionsEditorOpen",
                    "click button.ws-profileRecipe-full-directions-editor-save": "_directionsEditorSave",
                    "click button.ws-profileRecipe-full-directions-editor-cancel": "_directionsEditorClose"
                },

                render: function () {
                    console.log("views/profileRecipe/full::render");
                    var uri = this.model.get("uri"),
                        kind = uri.get("kind"),
                        context = this.model.toJSON(),
                        converter;

                    context._className = this.className;
                    context.name = uri.getReadable();
                    context.detailLink = "#" + kind + "/" + uri.id;
                    context.directionsFormatted = context.directions;

                    if (context.directions !== "" && context.directionsFormat === "md") {
                        converter = new Showdown.converter();
                        context.directionsFormatted = converter.makeHtml(context.directions);
                    }

                    this.$el.html(this.template(context));

                    if (this.model.get("isPublic")) {
                        this.$el.addClass(this.className + "-public");
                    }
                    else {
                        this.$el.removeClass(this.className + "-public");
                    }
                    if (this.model.get("useDescription") === "") {
                        this.$el.addClass(this.className + "-noDescription");
                    }
                    else {
                        this.$el.removeClass(this.className + "-noDescription");
                    }
                    if (this.model.get("directions") === "") {
                        this.$el.addClass(this.className + "-noDirections");
                    }
                    else {
                        this.$el.removeClass(this.className + "-noDirections");
                    }

                    this._nodes = {
                        error: this.$("." + this.className + "-error")
                    };

                    return this;
                },

                _directionsEditorOpen: function (e) {
                    console.log("views/profileUri/full::_directionsEditorOpen");
                    e.preventDefault();

                    this.$el.addClass(this.className + "-editDirections");
                },

                _directionsEditorClose: function (e) {
                    console.log("views/profileUri/full::_directionsEditorClose");
                    if (e) {
                        e.preventDefault();
                    }

                    this.$el.removeClass(this.className + "-editDirections");
                },

                _directionsEditorSave: function (e) {
                    console.log("views/profileUri/full::_directionsEditorSave");
                    e.preventDefault();

                    this.model.save(
                        {
                            directions: this.$("textarea." + this.className + "-directions-editor-content").val()
                        },
                        {
                            wait: true,
                            success: _.bind(
                                function () {
                                    console.log("views/profileUri/full::_directionsEditorSave - success");
                                    this._clearError();
                                    this._directionsEditorClose();
                                },
                                this
                            ),
                            error: _.bind(this._saveError, this)
                        }
                    );
                }
            }
        );
    }
);
