
define(
    'views/profileUri/full',[
        "backbone",
        "templates",
        "underscore"
    ],
    function (Backbone, templates, _) {
        "use strict";

        return Backbone.View.extend(
            {
                template: templates["profileUri/full.html"],
                className: "ws-profileUri-full",
                _nodes: null,

                events: {
                    "click button.ws-profileUri-full-makePublic": "_makePublic",
                    "click button.ws-profileUri-full-makePrivate": "_makePrivate",
                    "click a.ws-profileUri-full-description-openEditor": "_descriptionEditorOpen",
                    "click button.ws-profileUri-full-description-editor-save": "_descriptionEditorSave",
                    "click button.ws-profileUri-full-description-editor-cancel": "_descriptionEditorClose",
                    "click button.ws-profileUri-full-unlink": "_doUnlink"
                },

                initialize: function () {
                    console.log("views/profileUri/full::initialize");

                    this.listenTo(this.model, "change", this.render);
                },

                render: function () {
                    console.log("views/profileUri/full::render");
                    var uri = this.model.get("uri"),
                        kind = uri.get("kind"),
                        context = this.model.toJSON();

                    context._className = this.className;
                    context.name = uri.getReadable();
                    if (kind === "activity" || kind === "recipe") {
                        context.detailLink = "#" + kind + "/" + uri.id;
                        context._canUnlink = false;
                    }
                    else {
                        context.detailLink = "#uri/" + kind + "/" + uri.id;
                        context._canUnlink = true;
                    }

                    this.$el.html(this.template(context));

                    if (this.model.get("isPublic")) {
                        this.$el.addClass(this.className + "-public");
                    }
                    else {
                        this.$el.removeClass(this.className + "-public");
                    }
                    if (this.model.get("useDescription") === "") {
                        this.$el.addClass(this.className + "-noDescription");
                    }
                    else {
                        this.$el.removeClass(this.className + "-noDescription");
                    }

                    this._nodes = {
                        error: this.$("." + this.className + "-error")
                    };

                    return this;
                },

                _saveError: function (model, xhr) {
                    console.log("views/profileUri/full::_saveError");
                    this._nodes.error.html("Error: "  + xhr.responseText + " (" + xhr.status + ")");
                },

                _clearError: function () {
                    console.log("views/profileUri/full::_clearError");
                    this._nodes.error.html("");
                },

                _makePrivate: function () {
                    console.log("views/profileUri/full::_makePrivate");
                    this.model.save(
                        {
                            isPublic: false
                        },
                        {
                            wait: true,
                            success: _.bind(this._clearError, this),
                            error: _.bind(this._saveError, this)
                        }
                    );
                },

                _makePublic: function () {
                    console.log("views/profileUri/full::_makePublic");
                    this.model.save(
                        {
                            isPublic: true
                        },
                        {
                            wait: true,
                            success: _.bind(this._clearError, this),
                            error: _.bind(this._saveError, this)
                        }
                    );
                },

                _descriptionEditorOpen: function (e) {
                    console.log("views/profileUri/full::_descriptionEditorOpen");
                    e.preventDefault();

                    this.$el.addClass(this.className + "-editDescription");
                },

                _descriptionEditorClose: function (e) {
                    console.log("views/profileUri/full::_descriptionEditorClose");
                    if (e) {
                        e.preventDefault();
                    }

                    this.$el.removeClass(this.className + "-editDescription");
                },

                _descriptionEditorSave: function (e) {
                    console.log("views/profileUri/full::_descriptionEditorSave");
                    e.preventDefault();

                    this.model.save(
                        {
                            useDescription: this.$("textarea." + this.className + "-description-editor-content").val()
                        },
                        {
                            wait: true,
                            success: _.bind(
                                function () {
                                    console.log("views/profileUri/full::_descriptionEditorSave - success");
                                    this._clearError();
                                    this._descriptionEditorClose();
                                },
                                this
                            ),
                            error: _.bind(this._saveError, this)
                        }
                    );
                },

                _doUnlink: function () {
                    console.log("views/profileUri/full::_doUnlink");
                    this.model.destroy(
                        {
                            wait: true,
                            success: _.bind(this._clearError, this),
                            error: _.bind(this._saveError, this)
                        }
                    );
                }
            }
        );
    }
);
