
define(
    'views/about',[
        "backbone",
        "templates"
    ],
    function (Backbone, templates) {
        "use strict";

        return Backbone.View.extend(
            {
                template: templates["about.html"],
                className: "ws-about",

                render: function () {
                    this.$el.html(this.template());

                    return this;
                }
            }
        );
    }
);
