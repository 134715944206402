
define(
    'views/userAcctVerify',[
        "backbone",
        "templates",
        "jquery"
    ],
    function (Backbone, templates, $) {
        "use strict";

        return Backbone.View.extend(
            {
                template: templates["userAcctVerify.html"],
                className: "ws-userAcctVerify",

                render: function () {
                    this.$el.html(this.template());

                    this._failureMessageNode = this.$(".ws-userAcctVerify-failure-message");

                    return this;
                },

                doVerify: function (id, token) {
                    console.log("view/userAcctVerify::doVerify");
                    console.log("view/userAcctVerify::doVerify - id: " + id);
                    console.log("view/userAcctVerify::doVerify - token: " + token);
                    var requestCfg = {
                        code: token
                    };

                    this.$el.removeClass("success").removeClass("error");

                    $.ajax(
                        AppConfig.apiUrl + "/user/" + id + "/verification",
                        {
                            type: "PUT",
                            contentType: "application/json",
                            data: JSON.stringify(requestCfg),
                            processData: false,
                            success: this._requestSuccess,
                            error: this._requestError,
                            context: this
                        }
                    );
                },

                _requestSuccess: function () {
                    console.log("view/userAcctVerify::_requestSuccess");
                    this.$el.removeClass("error").addClass("success");
                },

                _requestError: function (xhr, status, error) {
                    console.log("view/userAcctVerify::_requestError");
                    this.$el.removeClass("success").addClass("error");
                    this._failureMessageNode.html(error + ": " + xhr.responseText + " (" + xhr.status + ")");
                }
            }
        );
    }
);
