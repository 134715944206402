
define(
    'views/home',[
        "backbone",
        "templates",
        "collection/profiles",
        "collection/uris",
        "views/profile/list",
        "views/verb/list",
        "views/extension/list",
        "views/activityType/list",
        "views/attachmentUsage/list",
        "views/home/user",

        // non-positional
        "backbone-subviews"
    ],
    function (
        Backbone,
        templates,
        CollectionProfiles,
        CollectionUris,
        ViewProfiles,
        ViewVerbs,
        ViewExtensions,
        ViewActivityTypes,
        ViewAttachmentUsages,
        ViewHomeUser
    ) {
        "use strict";

        return Backbone.View.extend(
            {
                template: templates["home.html"],
                className: "ws-home",
                _currentActive: null,
                _collections: null,
                _userView: null,
                _userViewContainer: null,

                subviewCreators: {
                    profiles: function () {
                        console.log("views/home::subviewCreators - profiles");
                        var view = new ViewProfiles(
                            {
                                collection: this._collections.profiles
                            }
                        );

                        return view;
                    },
                    verbs: function () {
                        console.log("views/home::subviewCreators - verbs");
                        var view = new ViewVerbs(
                            {
                                collection: this._collections.verbs
                            }
                        );

                        return view;
                    },
                    extensions: function () {
                        console.log("views/home::subviewCreators - extensions");
                        var view = new ViewExtensions(
                            {
                                collection: this._collections.extensions
                            }
                        );

                        return view;
                    },
                    attachmentUsages: function () {
                        console.log("views/home::subviewCreators - attachmentUsages");
                        var view = new ViewAttachmentUsages(
                            {
                                collection: this._collections.attachmentUsages
                            }
                        );

                        return view;
                    },
                    activityTypes: function () {
                        console.log("views/home::subviewCreators - activityTypes");
                        var view = new ViewActivityTypes(
                            {
                                collection: this._collections.activityTypes
                            }
                        );

                        return view;
                    }
                },

                initialize: function () {
                    Backbone.Subviews.add(this);

                    this._collections = {};

                    this._collections.activityTypes = new CollectionUris({ kind: "activityType" });
                    this._collections.activityTypes.fetch({reset: true});
                    this._collections.attachmentUsages = new CollectionUris({ kind: "attachmentUsage" });
                    this._collections.attachmentUsages.fetch({reset: true});
                    this._collections.extensions = new CollectionUris({ kind: "extension" });
                    this._collections.extensions.fetch({reset: true});
                    this._collections.profiles = new CollectionProfiles();
                    this._collections.profiles.fetch({reset: true});
                    this._collections.verbs = new CollectionUris({ kind: "verb" });
                    this._collections.verbs.fetch({reset: true});

                    if (typeof this.options.user !== "undefined") {
                        this.setUser(this.options.user);
                    }
                },

                render: function () {
                    console.log("views/home::render");
                    if (this._userView !== null) {
                        this._userView.$el.detach();
                    }

                    this.$el.html(this.template());

                    this._userViewContainer = this.$("div.ws-home-tab-profile");

                    if (this._userView !== null) {
                        this._userView.render();
                        this._userViewContainer.html(this._userView.el);
                    }

                    if (this._currentActive === null) {
                        if (this._userView !== null) {
                            this.setActiveTab("profile");
                        }
                        else {
                            this.setActiveTab("activityTypes");
                        }
                    }
                    else {
                        this.setActiveTab(this._currentActive);
                    }

                    return this;
                },

                setActiveTab: function (tab, subtab) {
                    console.log("views/home::setActiveTab: " + tab);
                    console.log("views/home::setActiveTab - subtab: " + subtab);

                    if (this._currentActive !== tab) {
                        // these get both pane and selector
                        this.$(".ws-home-tab.active").removeClass("active");
                        this.$(".ws-home-tab-" + tab).addClass("active");

                        this._currentActive = tab;
                    }
                    if (tab === "profile" && typeof subtab !== "undefined" && subtab !== null) {
                        this._userView.setProfileTab(subtab);
                    }
                },

                setUser: function (model) {
                    console.log("views/home::setUser");

                    if (! model) {
                        if (this._userView !== null) {
                            this._userView.remove();
                            this._userView = null;
                        }
                        if (this._currentActive === "profile") {
                            this.setActiveTab("activityTypes");
                        }
                        return;
                    }

                    if (this._userView === null) {
                        this._userView = new ViewHomeUser(
                            {
                                model: model
                            }
                        );
                        if (this._userViewContainer !== null) {
                            this._userView.render();

                            this._userViewContainer.html(this._userView.el);
                        }
                        return;
                    }

                    this._userView.setModel(model);
                },

                reloadCollection: function (label) {
                    console.log("views/home::reloadCollection: " + label);
                    if (! this._collections[label]) {
                        console.log("views/home::reloadCollection - unrecognized label: " + label);
                        return;
                    }

                    this._collections[label].fetch();
                }
            }
        );
    }
);
