
define(
    'collection/submissions',[
        "backbone",
        "model/submission"
    ],
    function (Backbone, Model) {
        "use strict";

        return Backbone.Collection.extend(
            {
                model: Model,
                url: AppConfig.apiUrl + "/submission"
            }
        );
    }
);
