
define(
    'views/profile/list',[
        "backbone",
        "templates",
        "views/list",
        "views/profile/summary"
    ],
    function (Backbone, templates, List, SubView) {
        "use strict";

        return List.extend(
            {
                subClassName: "ws-profile-list",
                modelViewCtr: SubView,
                noModels: "No public profiles to display."
            }
        );
    }
);
