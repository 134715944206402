
define(
    'views/submission/summary',[
        "backbone",
        "templates",
        "constants",
        "underscore"
    ],
    function (Backbone, templates, Constants, _) {
        "use strict";
        return Backbone.View.extend(
            {
                template: templates["submission/summary.html"],
                className: "ws-submission-summary",
                events: {
                    "click .ws-submission-summary-editor-save": "doSave"
                },
                _nodes: null,

                initialize: function () {
                    console.log("views/submission/summary::initialize");
                    this.listenTo(this.model, "change", this.render);
                },

                render: function () {
                    console.log("views/submission/summary::render");
                    var kind = this.model.get("kind"),
                        metadata = JSON.parse(this.model.get("metadata")),
                        context = {
                            id: this.model.get("id"),
                            comments: this.model.get("comments"),
                            kind: Constants.TYPE_MAP[kind].readable,
                            uriPrefix: this._getPrefix(kind),
                            uriSegment: "",
                            uri: "",
                            status: this.model.get("status"),
                            //
                            // parse and re-stringify to get pretty printing
                            //
                            metadataStringified: JSON.stringify(metadata, null, 4),
                            user: this.model.get("user").toJSON()
                        };

                    if (this.model.get("uri") !== null) {
                        if (this._isLocal(this.model.get("uri"), kind)) {
                            context.uriSegment = this.model.get("uri").slice(context.uriPrefix.length);
                        }
                        else {
                            this.$el.addClass("ws-submission-summary-editor-hasURI");
                            context.uri = this.model.get("uri");
                        }
                    }

                    if (typeof metadata.name !== "undefined") {
                        context.metaDataName = metadata.name["en-US"];
                    }
                    if (typeof metadata.description !== "undefined") {
                        context.metaDataDesc = metadata.description["en-US"];
                    }

                    this.$el.html(this.template(context));

                    this._nodes = {
                        status:           this.$(".ws-submission-summary-editor-status"),
                        comments:         this.$(".ws-submission-summary-editor-comments"),
                        uriSegment:       this.$(".ws-submission-summary-editor-uriSegment"),
                        metaDataName:     this.$(".ws-submission-summary-editor-metaData-name"),
                        metaDataDesc:     this.$(".ws-submission-summary-editor-metaData-desc"),
                        curationResponse: this.$(".ws-submission-summary-editor-curationResponse")
                    };

                    return this;
                },

                doSave: function (e) {
                    console.log("views/submission/summary::doSave");
                    e.preventDefault();

                    var metadata = this.model.get("metadata"),
                        settings = {
                            status: this._nodes.status.val(),
                            comments: this._nodes.comments.val()
                        };
                    if (metadata === null) {
                        metadata = "{}";
                    }

                    metadata = JSON.parse(metadata);

                    if (this.model.get("uri") === null || this._isLocal(this.model.get("uri"), this.model.get("kind"))) {
                        if (settings.status === "approved" && this._nodes.uriSegment.val() === "") {
                            this._saveFailure("URI Segment is required for approval");
                            return;
                        }
                        if (this._nodes.uriSegment.val() !== "") {
                            settings.uri = this._getPrefix(this.model.get("kind")) + this._nodes.uriSegment.val();
                        }
                    }

                    if (this._nodes.metaDataName.val() !== "") {
                        metadata.name = {
                            "en-US": this._nodes.metaDataName.val()
                        };
                    }
                    if (this._nodes.metaDataDesc.val() !== "") {
                        metadata.description = {
                            "en-US": this._nodes.metaDataDesc.val()
                        };
                    }

                    settings.metadata = JSON.stringify(metadata);

                    console.log("views/submission/summary::doSave - settings:", settings);
                    this.model.save(
                        settings,
                        {
                            success: _.bind(this._saveSuccess, this),
                            error: _.bind(this._saveError, this),
                            wait: true
                        }
                    );
                },

                _saveSuccess: function () {
                    this._nodes.curationResponse.html("Successfully saved");
                },

                _saveError: function (model, xhr) {
                    var reason = "";
                    if (xhr.status === 500) {
                        reason = xhr.statusText;
                    }
                    else {
                        reason = xhr.responseText;
                    }
                    reason += " (" + xhr.status + ")";

                    this._saveFailure(reason);
                },

                _saveFailure: function (reason) {
                    this._nodes.curationResponse.html("Failed to save: " + reason);
                },

                _getPrefix: function (kind) {
                    return AppConfig.canonicalBaseUri + "/" + Constants.TYPE_MAP[kind].uri + "/";
                },

                _isLocal: function (uri, kind) {
                    return uri.indexOf(this._getPrefix(kind)) === 0;
                }
            }
        );
    }
);
