
define(
    'collection/uris',[
        "backbone",
        "underscore",
        "model/uri"
    ],
    function (Backbone, _, Model) {
        "use strict";

        return Backbone.Collection.extend(
            {
                model: Model,
                kind: null,

                initialize: function (cfg) {
                    console.log("collection/uris::initialize: " + this.kind);
                    _.extend(this, _.pick(cfg, "kind"));
                },

                url: function () {
                    console.log("collection/uris::url: " + this.kind);
                    return AppConfig.apiUrl + "/uris/" + this.kind;
                }
            }
        );
    }
);
