
define(
    'collection/profileRequests',[
        "backbone",
        "model/profileRequest"
    ],
    function (Backbone, Model) {
        "use strict";

        return Backbone.Collection.extend(
            {
                model: Model,
                userId: null,
                status: null,

                url: function () {
                    console.log("collection/profileRequests::url");
                    var url;

                    if (this.userId === null) {
                        url = AppConfig.apiUrl + "/profile-request";
                    }
                    else {
                        url = AppConfig.apiUrl + "/user/" + this.userId + "/profile-request";
                    }

                    return url;
                },

                initialize: function (models, cfg) {
                    console.log("collection/profileRequests::initialize");

                    cfg = cfg || {};

                    if (typeof cfg.userId !== "undefined") {
                        console.log("collection/profileRequests::initialize - userId: " + cfg.userId);
                        this.userId = cfg.userId;
                    }
                },

                hasOpen: function () {
                    console.log("collection/profileRequests::hasOpen");

                    return this.find(
                        function (model) {
                            if (model.get("status") === "pending") {
                                return model;
                            }
                        }
                    );
                }
            }
        );
    }
);
