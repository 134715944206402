
define(
    'views/list',[
        "backbone",
        "templates",
        "underscore"
    ],
    function (Backbone, templates, _) {
        "use strict";

        var CLASS_EMPTY = "ws-list-empty";

        return Backbone.View.extend(
            {
                template: templates["list.html"],
                className: "ws-list",

                subClassName: null,
                modelViewCtr: null,
                noModels: "No models to render.",

                _rendered: false,
                _listContainerNode: null,
                _views: null,

                initialize: function (cfg) {
                    console.log("views/list::initialize");
                    this._views = {};

                    _.extend(this, _.pick(cfg, "noModels"));

                    if (typeof this.collection !== "undefined") {
                        this._bindCollection();
                    }
                },

                render: function () {
                    console.log("views/list::render");
                    this._rendered = true;

                    if (this.subClassName !== null) {
                        this.$el.addClass(this.subClassName);
                    }

                    this.$el.html(
                        this.template(
                            {
                                noModels: this.noModels
                            }
                        )
                    );

                    this._listContainerNode = this.$(".ws-list-listContainer");

                    if (typeof this.collection === "undefined" || this.collection.length === 0) {
                        this.$el.addClass(CLASS_EMPTY);
                    }
                    else {
                        this.$el.removeClass(CLASS_EMPTY);

                        this.collection.each(
                            this._renderModel,
                            this
                        );
                    }

                    return this;
                },

                setCollection: function (collection) {
                    console.log("views/list::setCollection");
                    this._unBindCollection();
                    this.collection = collection;
                    this._bindCollection();

                    if (this._rendered) {
                        this.render();
                    }
                },

                // FEATURE: this should probably take an index of where to render this model
                _renderModel: function (m) {
                    //console.log("views/list::_renderModel: " + m.cid);

                    if (! this._views[m.cid]) {
                        this._views[m.cid] = new this.modelViewCtr(
                            {
                                model: m
                            }
                        ).render();
                    }
                    this._listContainerNode.append(this._views[m.cid].el);
                },

                _bindCollection: function () {
                    console.log("views/list::_bindCollection");

                    if (typeof this.collection !== "undefined") {
                        this.listenTo(
                            this.collection,
                            "add",
                            this._handleAdd
                        );
                        this.listenTo(
                            this.collection,
                            "reset",
                            this._handleReset
                        );
                        this.listenTo(
                            this.collection,
                            "remove",
                            this._handleRemove
                        );
                    }
                },

                _unBindCollection: function () {
                    console.log("views/list::_unBindCollection");
                    if (typeof this.collection !== "undefined") {
                        this.stopListening(this.collection);
                    }
                },

                _handleAdd: function (m) {
                    console.log("views/list::_handleAdd");
                    this.$el.removeClass(CLASS_EMPTY);
                    this._renderModel(m);
                },

                _handleReset: function () {
                    console.log("views/list::_handleReset");
                    this.render();
                },

                _handleRemove: function () {
                    console.log("views/list::_handleRemove");
                    this.render();
                }
            }
        );
    }
);
