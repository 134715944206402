
define(
    'views/resetPassword',[
        "backbone",
        "templates",
        "jquery"
    ],
    function (Backbone, templates, $) {
        "use strict";

        return Backbone.View.extend(
            {
                template: templates["resetPassword.html"],
                className: "ws-resetPassword",

                events: {
                    "click .ws-resetPassword-send": "_doRequest"
                },

                _nodes: null,

                render: function () {
                    this.$el.html(this.template());

                    this._nodes = {
                        email:         this.$(".ws-resetPassword-email"),
                        indicator:     this.$(".ws-resetPassword-indicator"),
                        failureReason: this.$(".ws-resetPassword-message-failure-reason")
                    };

                    return this;
                },

                focusEmail: function () {
                    console.log("views/resetPassword::focusEmail");
                    this._nodes.email.focus();
                },

                _clearError: function () {
                    console.log("views/resetPassword::_clearError");
                    this._nodes.indicator.removeClass("error");
                },

                _doRequest: function (e) {
                    console.log("views/resetPassword::_doRequest");
                    e.preventDefault();

                    var email = this._nodes.email.val(),
                        errMsg = null,
                        requestCfg;

                    if (email === "") {
                        errMsg = "Required field empty: email";
                    }

                    if (errMsg !== null) {
                        console.log("views/resetPassword::_doRequest - errMsg: " + errMsg);
                        this._requestFailure(errMsg);
                        return;
                    }

                    requestCfg = {
                        email: email,
                        verifyUrl: AppConfig.siteUrl + "#resetPasswordVerify/" + encodeURIComponent(email) + "/:token"
                    };

                    $.ajax(
                        AppConfig.apiUrl + "/password-reset",
                        {
                            type: "POST",
                            contentType: "application/json",
                            processData: false,
                            data: JSON.stringify(requestCfg),
                            success: this._requestSuccess,
                            error: this._requestError,
                            context: this
                        }
                    );
                },

                _requestSuccess: function () {
                    console.log("views/resetPassword::_requestSuccess");

                    this._clearError();
                    this._nodes.indicator.addClass("success");
                },

                _requestError: function (xhr, status, error) {
                    console.log("views/resetPassword::_requestError");
                    var reason = "";
                    if (xhr.status === 401) {
                        reason = xhr.responseText;
                    }
                    else if (xhr.status === 404) {
                        reason = "Unrecognized user account";
                    }
                    else {
                        reason = error + " (" + xhr.status + ")";
                    }

                    this._requestFailure(reason);
                },

                _requestFailure: function (reason) {
                    console.log("views/resetPassword::_requestFailure");

                    reason = reason || "Unknown";

                    this._nodes.failureReason.html(reason);
                    this._nodes.indicator.addClass("error");
                }
            }
        );
    }
);
