
define(
    'views/profile/summary',[
        "backbone",
        "templates",
        "showdown"
    ],
    function (Backbone, templates, Showdown) {
        "use strict";

        return Backbone.View.extend(
            {
                template: templates["profile/summary.html"],
                className: "ws-profile-summary",

                initialize: function () {
                    this.listenTo(this.model, "change", this.render);
                },

                render: function () {
                    var context = this.model.toJSON(),
                        converter;

                    context.detailLink = "#profile/" + this.model.get("id");
                    if (context.description !== "") {
                        converter = new Showdown.converter();
                        context.descriptionFormatted = converter.makeHtml(context.description);
                    }

                    if (this.model.get("isPublic")) {
                        this.$el.addClass("ws-profile-public");
                    }
                    else {
                        this.$el.removeClass("ws-profile-public");
                    }

                    this.$el.html(this.template(context));

                    return this;
                }
            }
        );
    }
);
